import React, { useEffect, useState } from 'react'
import '../../features/Coupon/AddCouponFrom.css';
import { getLocations } from '../../apis/locations';
import Loading from '../../ReusableFunctions/Loader';
import Popup from '../../ReusableFunctions/Popup';
import { createCoupon, getCoupon, updateCoupon } from '../../apis/coupons';
import Modal from '../../ReusableComponents/Modal';
import { Button } from '../../Components/Helper';
import Form from '../../ReusableComponents/Form';
import InputField from '../../ReusableComponents/InputField';
import Select from "react-select"
import ToggleField from '../../ReusableComponents/ToggleField';
import { NoOfUserValidation, couponMessageValidation, discountCostValidation, discountValidation, redemptionCountValidation, selectFieldValidation } from '../../utils/inputValidations';
import CustomToggle from '../../ReusableComponents/CustomToggle';
import CustomSelect from '../../ReusableComponents/CustomSelect';
import TextAreaField from '../../ReusableComponents/textAreaField';
import DatePicker from "react-datepicker";
import Calender from "../../assets/blueCalendar.svg"
import { GLOBAL_STYLES } from '../../appConstants';

export default function AddCouponForm({ couponId, onClose }) {
    const defaultStyles = {
        control: (provided, state) => ({
            ...provided,
            fontSize: "12px",
            background: "#F3F3F3",
            borderRadius: "5px",
            borderColor: GLOBAL_STYLES.BORDER_COLOR,
            outline: "none",
            fontWeight: 600,
            color: "black",
            // height: "35px",
        }),
        option: (provided, state) => ({
            ...provided,
            fontSize: "12px",
            boxShdow: GLOBAL_STYLES.BOX_SHADOW,
            color: state.isFocused && !state.isSelected ? "black" : state.isSelected ? "white" : state.isSelected && state.isFocused ? "white" : "black",
            backgroundColor: state.isFocused && !state.isSelected ? "rgba(0, 0, 0, 0.1)" : state.isSelected ? GLOBAL_STYLES.BG_ACTIVE : GLOBAL_STYLES.BG_INACTIVE,
            minHeight: "35px",
        }),
        valueContainer: (provided, state) => ({
            ...provided,
        }),
        menu: (provided, state) => ({
            ...provided,
        }),
        menuList: (provided, state) => ({
            ...provided,
        }),
        indicatorsContainer: (provided, state) => ({
            ...provided,
        }),
        indicatorContainer: (provided, state) => ({
            ...provided,
        }),
        dropdownIndicator: (provided, state) => ({
            ...provided,
        }),
        singleValue: (provided, state) => ({
            ...provided,
        }),
        loadingIndicator: (provided, state) => ({
            ...provided,
            color: "#a1bffd",
            fontSize: "5px",
        }),
        menuPortal: (base) => ({ ...base, zIndex: 9999 })
    };
    const [locations, setLocations] = useState([])
    const [showFromDatePicker, setShowFromDatePicker] = useState(false)
    const [showToDatePicker, setShowToDatePicker] = useState(false)
    const [fromDateError, setFromDateError] = useState('')
    const [toDateError, setToDateError] = useState('')
    const [coupon, setCoupon] = useState({
        name: "",
        discount_percentage: null,
        discount_cost: null,
        max_discount_value: null,
        min_order_value: null,
        max_consumption_amount: 100000,
        max_redemption_count: null,
        number_of_use_per_user: null,
        is_location_specific: false,
        expiry_date: new Date(),
        from_date: new Date(),
        description: "",
        coupon_code: "",
        status: true,
        is_discount_in_percentage: true,
        dsp_id: undefined,
        consumed_amount: 0,
        is_dsp_specific: false,
        locations: [],
        is_public: true,
        is_locations_edited: false
    })

    const fetchDspInitData = async () => {
        try {
            Loading.set(true);
            const locations = await getLocations();
            setLocations(locations)
            if (couponId) {
                const data = await getCoupon(couponId);
                setCoupon(prev => ({
                    ...prev,
                    ...data?.coupon,
                    locations: data?.coupon?.locations.map((l) => {
                        const location = locations.find((lc) => lc.id === l);
                        return { label: location?.name, value: l };
                    }),
                    expiry_date: new Date(data.coupon.expiry_date),
                    from_date: new Date(data.coupon.from_date),
                    is_discount_in_percentage: Boolean(data.coupon.is_discount_in_percentage)
                }));
            }
            Loading.set(false);
        } catch (err) {
            Loading.set(false);
            Popup.alert("Coupon", "Something Went wrong while fetch locations !", 'ERROR')
        }
    }

    const handleAddCoupon = (c) => {
        Loading.set(true);
        let modifiedCoupon = {
            ...c,
            expiry_date: new Date(coupon.expiry_date).setHours(23, 59, 59, 999),
            from_date: new Date(coupon.from_date).setHours(0, 0, 0, 0),
            locations: coupon?.locations.map((l) => l?.value)
        }
        modifiedCoupon = { ...modifiedCoupon, expiry_date: new Date(modifiedCoupon.expiry_date).toISOString(), from_date: new Date(modifiedCoupon.from_date).toISOString() }
        createCoupon(modifiedCoupon).then((res) => {
            Popup.alert("Coupon", "Coupon created successfully !", 'SUCCESS')
            Loading.set(false);
            onClose()
        }).catch((err) => {
            console.log("error", err);
            Loading.set(false);
            Popup.alert("Coupon", "Something Went wrong while creating coupon !", 'ERROR')
        })
    }

    const handleUpdateCoupon = (c) => {
        Loading.set(true);
        let modifiedCoupon = {
            ...c,
            expiry_date: new Date(coupon.expiry_date).setHours(23, 59, 59, 999),
            from_date: new Date(coupon.from_date).setHours(0, 0, 0, 0),
            locations: coupon?.locations.map((l) => l?.value),
            is_locations_edited: true
        }
        modifiedCoupon = { ...modifiedCoupon, expiry_date: new Date(modifiedCoupon.expiry_date).toISOString(), from_date: new Date(modifiedCoupon.from_date).toISOString() }
        updateCoupon(modifiedCoupon).then((res) => {
            Popup.alert("Coupon", "Coupon updated successfully !", 'SUCCESS')
            Loading.set(false);
            onClose()
        }).catch((err) => {
            console.log("error", err);
            Loading.set(false);
            Popup.alert("Coupon", "Something Went wrong while updating coupon !", 'ERROR')
        })
    }

    useEffect(() => {
        Loading.set(true);
        fetchDspInitData();
    }, [])

    return (
        <Modal
            header={couponId ? "Edit Coupon" : "Create Coupon"}
            open={true}
            onClose={onClose}
            contentStyle={{ width: "725px", height: "760px", borderRadius: "10px" }}
            headerContainerStyle={{ padding: "25px 30px" }}
        >
            <Form
                onSubmitForm={(coupon) => {
                    couponId ? handleUpdateCoupon(coupon) : handleAddCoupon(coupon)
                }}
                initialValues={coupon}
                style={{ display: 'flex', flexDirection: 'column', width: "100%", height: 'calc(100% - 75px)', padding: "20px 30px" }}
            >
                {(onSubmit, formValue) => (
                    <>
                        <div
                            id='coupen-form'
                            style={{
                                width: "100%",
                                height: '100%',
                                display: 'grid',
                                gridTemplateColumns: '1fr 1fr',
                                // gridTemplateRows: "repeat(auto-fill, minmax(80px, 1fr))",
                                gridAutoRows: 'auto',
                                gap: '5px 25px',
                                overflow: 'auto'
                            }} >
                            <div className='input_field'>
                                <CustomSelect
                                    label={"Visibility"}
                                    name={"is_public"}
                                    value={coupon.is_public}
                                    onChange={(value) => {
                                        setCoupon(prev => ({ ...prev, is_public: value }))
                                    }}

                                />
                            </div>
                            <div className='input_field'>
                                <CustomToggle
                                    onText={"Percentage"}
                                    offText={'Rupees'}
                                    label={"Discount Type"}
                                    name={"is_discount_in_percentage"}
                                    handleChange={(value) => {
                                        setCoupon(prev => ({ ...prev, is_discount_in_percentage: value }))
                                    }}
                                />
                            </div>
                            <InputField
                                label={"Coupon Code"}
                                placeholder={"Enter coupon code "}
                                name={'coupon_code'}
                                validation={{
                                    required: 'Required',
                                    maxLength: {
                                        value: 15,
                                        message: 'Max 15 characters.',
                                    },
                                    minLength: {
                                        value: 3,
                                        message: "At least 3 characters.",
                                    },
                                }}
                                type={'text'}
                                handleChange={(val) => {
                                    setCoupon(prev => ({ ...prev, coupon_code: val }))
                                }}
                            />

                            <InputField
                                label={"Minimum Order Value"}
                                placeholder={"Enter minimum order amount "}
                                name={'min_order_value'}
                                type={'text'}
                                validation={discountCostValidation}
                                handleChange={(val) => {
                                    setCoupon(prev => ({ ...prev, min_order_value: val }))
                                }}
                            />
                            <TextAreaField
                                label={"Message To Be Displayed On Coupon"}
                                name={"description"}

                                placeholder={"Message to display on coupon"}
                                type={"textarea"}
                                style={{ resize: "none", height: "145px", padding: "6px 8px", background: "#F3F3F3", outline: "none" }}
                                validation={couponMessageValidation}
                                handleChange={(val) => {
                                    setCoupon(prev => ({ ...prev, description: val }))
                                }}
                            />
                            <div style={{ display: "flex", flexDirection: "column", gap: "32px" }}>
                                {!formValue.is_discount_in_percentage &&
                                    <InputField
                                        label={"Fixed Discount Cost"}
                                        placeholder={"Enter discount price "}
                                        name={'discount_cost'}
                                        type={'text'}
                                        validation={discountCostValidation}
                                        handleChange={(val) => {
                                            setCoupon(prev => ({ ...prev, discount_cost: val }))
                                        }}
                                    />
                                }
                                {formValue.is_discount_in_percentage &&
                                    <InputField
                                        label={"Discount Percentage %"}
                                        type={'text'}
                                        placeholder={"Enter discount percentage "}
                                        name={'discount_percentage'}
                                        validation={discountValidation}
                                        handleChange={(val) => {
                                            setCoupon(prev => ({ ...prev, discount_percentage: val }))
                                        }}
                                    />
                                }
                                {formValue.is_discount_in_percentage &&
                                    <InputField
                                        label={"Maximum Discount Amount"}
                                        placeholder={"Enter maximum discount amount "}
                                        name={'max_discount_value'}
                                        type={'text'}
                                        validation={discountCostValidation}
                                        handleChange={(val) => {
                                            setCoupon(prev => ({ ...prev, max_discount_value: val }))
                                        }}
                                    />
                                }
                            </div>

                            <div style={{}}>
                                <div style={{ fontSize: '13px', color: '#3B3B3B' }}>Validity</div>
                                <div style={{ display: "flex", width: "100%", justifyContent: "space-between", gap: '30px' }}>
                                    <div className='input_field' style={{ gap: "5px", width: '100%' }}>
                                        <label htmlFor="expiryDate" style={{ fontSize: '13px', color: '#3B3B3B', }}>{'From Date'}</label>
                                        <div
                                            className='input-date'
                                            style={{ position: 'relative', display: 'flex', flexDirection: 'column', rowGap: '5px', justifyContent: 'center', width: "100%", height: "35px", border: '1.4px solid rgba(217, 217, 217, 0.84)', }}
                                            onClick={() => setShowFromDatePicker(true)}
                                        >
                                            <DatePicker
                                                readOnly
                                                minDate={new Date()}
                                                onClickOutside={() => setShowFromDatePicker(false)}
                                                open={showFromDatePicker}
                                                value={new Date(coupon.from_date)}
                                                selected={new Date(coupon.from_date)}
                                                className="input-date-hidden"
                                                shouldCloseOnSelect={true}
                                                containerStyle={{ width: '100%' }}
                                                onChange={(date, e) => {
                                                    e.stopPropagation()
                                                    const expiryDate = new Date(coupon.expiry_date);
                                                    if (date <= expiryDate) {
                                                        setFromDateError("")
                                                    } else {
                                                        setFromDateError("From date cannot be greater than To date")
                                                    }
                                                    setCoupon(prev => ({ ...prev, from_date: new Date(date) }))
                                                    setShowFromDatePicker(false)

                                                    if (expiryDate >= date) {
                                                        setToDateError("")
                                                    } else {
                                                        setToDateError("To date cannot be less than From date")
                                                    }
                                                }}
                                            />
                                            <img src={Calender} alt='Calender' style={{ width: "18px", position: "absolute", right: "15px" }} />
                                        </div>
                                        <span style={{ fontSize: '10px', color: 'red' }}>{fromDateError}</span>
                                    </div>
                                    <div className='input_field' style={{ gap: "5px", width: '100%' }}>
                                        <label htmlFor="expiryDate" style={{ fontSize: '13px', color: '#3B3B3B', }}>{'To Date'}</label>
                                        <div
                                            className='input-date'
                                            style={{ position: 'relative', display: 'flex', flexDirection: 'column', rowGap: '5px', justifyContent: 'center', width: "100%", height: "35px", border: '1.4px solid rgba(217, 217, 217, 0.84)', }}
                                            onClick={() => setShowToDatePicker(true)}
                                        >
                                            <DatePicker
                                                readOnly
                                                minDate={new Date()}
                                                onClickOutside={() => setShowToDatePicker(false)}
                                                open={showToDatePicker}
                                                value={new Date(coupon.expiry_date)}
                                                selected={new Date(coupon.expiry_date)}
                                                className="input-date-hidden"
                                                shouldCloseOnSelect={true}
                                                containerStyle={{ width: '100%' }}
                                                onChange={(date, e) => {
                                                    e.stopPropagation()
                                                    const fromDate = new Date(coupon.from_date);
                                                    if (date >= fromDate) {
                                                        setToDateError("")
                                                    } else {
                                                        setToDateError("To date cannot be less than From date")
                                                    }
                                                    setCoupon(prev => ({ ...prev, expiry_date: new Date(date) }))
                                                    setShowToDatePicker(false)

                                                    if (fromDate <= date) {
                                                        setFromDateError("")
                                                    } else {
                                                        setFromDateError("From date cannot be greater than To date")
                                                    }
                                                }}
                                            />
                                            <img src={Calender} alt='Calender' style={{ width: "18px", position: "absolute", right: "15px" }} />
                                        </div>
                                        <span style={{ fontSize: '10px', color: 'red' }}>{toDateError}</span>
                                    </div>
                                </div>
                            </div>

                            <InputField
                                label={"Maximum Usage Per User"}
                                placeholder={"Enter number of use per user "}
                                name={'number_of_use_per_user'}
                                type={'text'}
                                containerStyle={{ marginTop: "20px" }}
                                validation={NoOfUserValidation}
                                handleChange={(val) => {
                                    setCoupon(prev => ({ ...prev, number_of_use_per_user: val }))
                                }}
                            />

                            {/* {!formValue.is_discount_in_percentage && <div style={{ width: "100%" }}></div>} */}
                            <div style={{ display: "flex", gap: "2px", flexDirection: "column" }}>
                                <ToggleField
                                    name={'is_location_specific'}
                                    offText={"Select Location"}
                                    onText={" "}
                                    labelStyle={{ fontSize: '13px', color: '#3B3B3B', }}
                                    handleChange={(e) => {
                                        console.log(e)
                                        setCoupon(prev => ({ ...prev, is_location_specific: e.target.checked }))
                                    }}
                                />
                                {coupon.is_location_specific &&
                                    <div style={{ marginTop: "-9px", position: 'relative' }}>
                                        <div style={{ display: "flex", flexDirection: "column", rowGap: "5px", position: "relative", }}>
                                            <Select
                                                menuPlacement='auto'
                                                classNamePrefix="react-select"
                                                theme={(theme) => ({
                                                    ...theme,
                                                    colors: {
                                                        ...theme.colors,
                                                        primary25: GLOBAL_STYLES.BG_ACTIVE,
                                                        primary: GLOBAL_STYLES.BG_ACTIVE
                                                    }
                                                })}
                                                name='locations'
                                                isMulti
                                                styles={defaultStyles}
                                                value={coupon?.locations}
                                                options={locations.map(l => ({ label: l.name, value: l.id }))}
                                                containerStyle={{
                                                    width: "100%",
                                                    maxWidth: "100%",
                                                    minHeight: "80px",
                                                }}
                                                menuPortalTarget={document.getElementById('modalHolder')}
                                                validation={selectFieldValidation}
                                                controlStyle={{ height: "auto" }}
                                                onChange={(val) => {
                                                    setCoupon(prev => ({ ...prev, locations: val }))
                                                }}
                                            />
                                        </div>
                                    </div>
                                }

                            </div>

                            <InputField
                                label={"Maximum Redemption Count"}
                                placeholder={"Enter maximum redemption count"}
                                name={'max_redemption_count'}
                                type={'text'}
                                containerStyle={{ marginTop: "14px" }}
                                validation={redemptionCountValidation}
                                handleChange={(val) => {
                                    setCoupon(prev => ({ ...prev, max_redemption_count: val }))
                                }}
                            />

                        </div>
                        <div
                            style={{
                                display: "flex",
                                flexDirection: "row-reverse",
                                padding: "10px 0px 15px",
                                gap: "20px"
                            }}
                        >
                            <Button
                                isDisabled={fromDateError || toDateError || (coupon.is_location_specific ? coupon.locations.length == 0 : false)}
                                text={couponId ? "Update" : "Create"}
                                className={"btn-large"}
                                type={"submit"}
                                onClick={onSubmit}
                            />
                            <Button
                                text={"Cancel"}
                                type={"button"}
                                onClick={onClose}
                                isBtnNotActive={true}
                                className={"btn-large "}
                            />
                        </div>
                    </>
                )}
            </Form>
        </Modal>
    )
}
