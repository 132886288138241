import { SERVER_URLS } from "../../appConstants";
import { getToken } from "../authentication";

export const getAdminDashboardOverview = ({ is_dsp_active }) => {
    return new Promise(async (resolve, reject) => {
        try {
            const token = getToken();
            const response = await fetch(
                SERVER_URLS.BACKEND_SERVER + `/aggregator/overview?temp=true${is_dsp_active ? `&is_dsp_active=true` : ""}`,
                {
                    method: "GET",
                    headers: {
                        Authorization: `Bearer ${token}`,
                    },
                }
            );
            const data = await response.json();
            if (response.ok) {
                resolve(data);
            } else {
                reject(
                    data?.message || "Something went wrong while getting overview."
                );
            }
        } catch (error) {
            reject("Something went wrong while getting overview.");
        }
    });
}

export const getStatusWiseRequestCount = (filter, searchQuery, fromDate, toDate) => {
    return new Promise(async (resolve, reject) => {
        try {
            const token = getToken();
            const queryParams = new URLSearchParams();

            if (fromDate && toDate) {
                queryParams.append("fromDate", fromDate);
                queryParams.append("toDate", toDate);
            }

            if (filter.isDspActive !== null && filter.isDspActive !== undefined) {
                queryParams.append("isDspActive", filter.isDspActive);
            }
            if (searchQuery) {
                queryParams.append("searchQuery", searchQuery);
            }
            if (filter?.services?.length > 0) {
                queryParams.append("services", encodeURIComponent(JSON.stringify(filter.services)));
            }
            if (filter?.dsps?.length > 0) {
                queryParams.append("dsps", encodeURIComponent(JSON.stringify(filter.dsps)));
            }
            if (filter?.statuses?.length > 0) {
                queryParams.append("statuses", encodeURIComponent(JSON.stringify(filter.statuses)));
            }
            if (filter?.locations?.length > 0) {
                queryParams.append("locations", encodeURIComponent(JSON.stringify(filter.locations)));
            }

            const url = `${SERVER_URLS.BACKEND_SERVER}/aggregator/overview/requests?${queryParams.toString()}`;

            const response = await fetch(url, {
                method: "GET",
                headers: {
                    Authorization: `Bearer ${token}`,
                },
            });

            const data = await response.json();
            if (response.ok) {
                resolve(data);
            } else {
                reject(
                    data?.message || "Something went wrong while getting overview."
                );
            }
        } catch (error) {
            reject("Something went wrong while getting overview.");
        }
    });
}

