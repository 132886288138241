import { useLocation } from "react-router"
import bhumeet from "../../../assets/bhumeet.svg"
import { useEffect, useState } from "react"
import Loading from "../../../ReusableFunctions/Loader"
import Popup from "../../../ReusableFunctions/Popup"
import dateFormat from 'dateformat';
import html2canvas from 'html2canvas'
import jsPDF from 'jspdf'
import dashLine from "../../../assets/dashLine.svg"
import { formatToIndianRupees } from "../../../ReusableFunctions/reusableFunctions"
import { getUserInvoiceDetails } from "../../../apis/adminAPIs/finance/userInvoices"
import { getInvoiceInDetail } from "../../../ReusableFunctions/RequestInvoice"

const UserInvoiceDetails = () => {
    const { dsp, invoice } = useLocation()?.state
    const [settInvoice, setSettInvoice] = useState({})
    const [userInvoice, setUserInvoice] = useState({})

    const getUserInvoiceDetailsFunc = async () => {
        try {
            Loading.set(true)
            const userInvoice = await getUserInvoiceDetails(invoice?.request_id)
            setUserInvoice(userInvoice)
            const { pesticide_per_acre_subsidy, service_amount, total_amount, cgst, sgst, pesticides_subsidy, service_amount_with_subsidy, applied_coupons, applied_discounts } = userInvoice
            const { field_area, service_completed_area, price_by } = userInvoice?.request
            const involiceDetails = await getInvoiceInDetail(field_area, service_completed_area, price_by, pesticide_per_acre_subsidy, service_amount, total_amount, cgst, sgst, pesticides_subsidy, service_amount_with_subsidy, applied_coupons, applied_discounts)
            setSettInvoice(involiceDetails)
            Loading.set(false)
        } catch (error) {
            console.log('error:', error)
            Loading.set(false)
            Popup.alert("Error", error, "ERROR")
        }
    }

    const downloadPDF = () => {
        const input = document.querySelector('.myDiv');

        html2canvas(input, {
            scale: 3,
            useCORS: true,
            backgroundColor: null,
        }).then(canvas => {
            const imgWidth = 210;
            const pageHeight = 297;
            const imgHeight = (canvas.height * imgWidth) / canvas.width;
            const heightLeft = imgHeight;

            const pdf = new jsPDF({
                orientation: 'portrait',
                unit: 'mm',
                format: 'a4',
            });

            let position = 0;

            pdf.addImage(
                canvas.toDataURL('image/png'),
                'PNG',
                0,
                position,
                imgWidth,
                imgHeight
            );

            let remainingHeight = heightLeft;
            while (remainingHeight > pageHeight) {
                position -= pageHeight;
                pdf.addPage();
                pdf.addImage(
                    canvas.toDataURL('image/png'),
                    'PNG',
                    0,
                    position,
                    imgWidth,
                    imgHeight
                );
                remainingHeight -= pageHeight;
            }

            pdf.save('invoice.pdf');
        });
    };

    useEffect(() => {
        getUserInvoiceDetailsFunc()
    }, [])

    return (
        settInvoice ?
            <div style={{ overflow: "auto", position: "relative" }}>
                <a
                    onClick={downloadPDF}
                    style={{
                        position: 'absolute',
                        top: '10px',
                        right: '340px',
                        textDecoration: 'underline',
                        color: '#007BFF',
                        cursor: 'pointer',
                        fontSize: '14px',
                    }}
                >
                    Download Invoice
                </a>
                <div style={{
                    width: '620px',
                    margin: '40px auto',
                    border: '1px solid rgba(215, 218, 224, 0.4)',
                    borderRadius: '5px',
                    boxShadow: '0 0 10px rgba(0,0,0,0.1)',
                    color: "#3B3B3B",
                    backgroundColor: '#fff'
                }}>
                    <div className='myDiv'>
                        <div style={{
                            display: 'flex',
                            justifyContent: 'space-between',
                            alignItems: 'flex-start',
                            margin: '0px 30px',
                            padding: '20px 0px',
                            borderBottom: '1px solid rgba(215, 218, 224, 0.3)',
                        }}>
                            <div>
                                <img src={bhumeet} alt="bhumeet" width={"100px"} />
                            </div>
                            <div style={{ textAlign: 'right' }}>
                                <p style={{ margin: '0', fontSize: "13px", }}><span style={{ fontWeight: 600, color: "#1A1C21" }}>Invoice No.</span> - {invoice?.invoice_number || "-"}</p>
                            </div>
                        </div>

                        <div style={{
                            display: 'flex',
                            justifyContent: 'space-between',
                            color: "#727272",
                            padding: "20px 30px"
                        }}>
                            <div>
                                <div style={{ marginBottom: '20px' }}>
                                    <p style={{ margin: '0', fontWeight: 500 }}>To</p>
                                    <p style={{ margin: '0', fontWeight: 'bold', color: "#1E1E1E" }}>{userInvoice?.customer_name || "-"}</p>
                                    <p style={{ margin: '0' }}>{userInvoice?.location_name || "-"}</p>
                                </div>
                                <div>
                                    <p style={{ margin: '0', fontWeight: 500 }}>From</p>
                                    <p style={{ margin: '0', fontWeight: 'bold', color: "#1E1E1E" }}>BhuMeet Digital Pvt. Ltd.</p>
                                    <p style={{ margin: '0' }}>Nashik, Maharashtra, India.</p>
                                    <p style={{ margin: '0' }}>GSTIN - 27AAKCP1648Q1ZO</p>
                                </div>
                            </div>
                        </div>

                        <div style={{
                            borderTop: '1px solid rgba(215, 218, 224, 0.6)',
                            paddingTop: '10px',
                            margin: "10px 30px 30px"
                        }}>
                            <p style={{ margin: '0', fontSize: "14px" }}><span style={{ fontWeight: 600, color: "#5E6470" }}>Invoice Date - </span><span style={{ color: "#5E6470" }}>{dateFormat(settInvoice?.generated_for_date, "dd mmm yyyy")}</span></p>
                        </div>

                        <div style={{
                            backgroundColor: '#D9D9D9',
                            padding: '10px 25px',
                            display: 'flex',
                            justifyContent: 'space-between',
                            fontWeight: 700,
                            color: '#1E1E1E',
                            height: "50px",
                            alignItems: "center",
                            fontSize: "18px"
                        }}>
                            <span>Payment Summary</span>
                            <span>Rates</span>
                        </div>

                        <div style={{ padding: '10px 30px 50px' }}>
                            <div style={{ marginBottom: '10px' }}>
                                <div style={{ display: 'flex', justifyContent: 'space-between', fontWeight: 500 }}>
                                    <span>Service Charges</span>
                                    <span>₹ {formatToIndianRupees(settInvoice?.service_amount || 0)}</span>
                                </div>
                            </div>
                            {settInvoice?.pesticides_subsidy > 0 &&
                                <div style={{ marginBottom: '10px' }}>
                                    <div style={{ display: 'flex', justifyContent: 'space-between', fontWeight: 500 }}>
                                        <span>Total Subsidy</span>
                                        <span style={{}}>- ₹ {formatToIndianRupees(settInvoice?.pesticides_subsidy || 0)}</span>
                                    </div>
                                </div>
                            }
                            {settInvoice?.applied_dsp_coupon_total_amount > 0 &&
                                <div style={{ marginBottom: '10px' }}>
                                    <div style={{ display: 'flex', justifyContent: 'space-between', fontWeight: 500 }}>
                                        <span>DSP Coupon</span>
                                        <span style={{}}>- ₹ {formatToIndianRupees(settInvoice?.applied_dsp_coupon_total_amount || 0)}</span>
                                    </div>
                                </div>
                            }
                            {settInvoice?.applied_bhumeet_coupon_total_amount > 0 &&
                                <div style={{ marginBottom: '10px' }}>
                                    <div style={{ display: 'flex', justifyContent: 'space-between', fontWeight: 500 }}>
                                        <span>Bhumeet Coupon</span>
                                        <span style={{}}>- ₹ {formatToIndianRupees(settInvoice?.applied_bhumeet_coupon_total_amount || 0)}</span>
                                    </div>
                                </div>
                            }
                            {settInvoice?.discount_amount > 0 &&
                                <div style={{ marginBottom: '10px' }}>
                                    <div style={{ display: 'flex', justifyContent: 'space-between', fontWeight: 500 }}>
                                        <span>Discount</span>
                                        <span style={{}}>- ₹ {formatToIndianRupees(settInvoice?.discount_amount || 0)}</span>
                                    </div>
                                </div>
                            }
                            {settInvoice?.applied_csgt_amount > 0 &&
                                <div style={{ marginBottom: '10px' }}>
                                    <div style={{ display: 'flex', justifyContent: 'space-between', fontWeight: 500 }}>
                                        <span>CGST</span>
                                        <span style={{}}>+ ₹ {formatToIndianRupees(settInvoice?.applied_csgt_amount || 0)}</span>
                                    </div>
                                </div>
                            }
                            {settInvoice?.applied_sgst_amount > 0 &&
                                <div style={{ marginBottom: '10px' }}>
                                    <div style={{ display: 'flex', justifyContent: 'space-between', fontWeight: 500 }}>
                                        <span>SGST</span>
                                        <span style={{}}>+ ₹ {formatToIndianRupees(settInvoice?.applied_sgst_amount || 0)}</span>
                                    </div>
                                </div>
                            }
                            {/* <img src={dashLine} alt="dashLine" style={{ maxWidth: "103%" }} /> */}
                            <div style={{
                                display: 'flex',
                                justifyContent: 'space-between',
                                paddingTop: '10px',
                                marginTop: '10px',
                                marginBottom: '20px',
                                fontWeight: 'bold',
                                borderTop: '1px solid rgba(215, 218, 224, 0.6)',
                                borderBottom: '1px solid rgba(215, 218, 224, 0.6)',
                                paddingBottom: '10px',
                                fontSize: "18px"
                            }}>
                                <span>Total Amount</span>
                                <span>₹ {formatToIndianRupees(settInvoice?.total_amount || 0)}</span>
                            </div>
                        </div>
                    </div>
                </div>
            </div> : <></>
    )
}

export default UserInvoiceDetails