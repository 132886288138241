import { useLocation } from "react-router"
import bhumeet from "../../assets/bhumeet.svg"
import { useEffect, useState } from "react"
import Loading from "../../ReusableFunctions/Loader"
import Popup from "../../ReusableFunctions/Popup"
import dateFormat from 'dateformat';
import { getSettlementInvoiceDetails } from "../../apis/settlements"
import { formatToIndianRupees } from "../../ReusableFunctions/reusableFunctions"
import html2canvas from 'html2canvas'
import jsPDF from 'jspdf'
import dashLine from "../../assets/dashLine.svg"

const DspInvoiceDetails = () => {
    const { dsp, invoice } = useLocation().state
    const [settInvoice, setSettInvoice] = useState({})

    const getSettelementInvoiceDetailsFunc = async () => {
        try {
            Loading.set(true)
            const settleInvoice = await getSettlementInvoiceDetails(invoice?.id)
            setSettInvoice({
                ...settleInvoice,
                time: new Date(settleInvoice?.time),
                invoice_id: invoice?.id,
                generated_time: new Date(settleInvoice?.generated_time)
            })
            Loading.set(false)
        } catch (error) {
            Loading.set(false)
            Popup.alert("Error", error, "ERROR")
        }
    }

    const downloadPDF = () => {
        const input = document.querySelector('.myDiv');

        html2canvas(input, {
            scale: 3,
            useCORS: true,
            backgroundColor: null,
        }).then(canvas => {
            const imgWidth = 210;
            const pageHeight = 297;
            const imgHeight = (canvas.height * imgWidth) / canvas.width;
            const heightLeft = imgHeight;

            const pdf = new jsPDF({
                orientation: 'portrait',
                unit: 'mm',
                format: 'a4',
            });

            let position = 0;

            pdf.addImage(
                canvas.toDataURL('image/png'),
                'PNG',
                0,
                position,
                imgWidth,
                imgHeight
            );

            let remainingHeight = heightLeft;
            while (remainingHeight > pageHeight) {
                position -= pageHeight;
                pdf.addPage();
                pdf.addImage(
                    canvas.toDataURL('image/png'),
                    'PNG',
                    0,
                    position,
                    imgWidth,
                    imgHeight
                );
                remainingHeight -= pageHeight;
            }

            pdf.save('invoice.pdf');
        });
    };

    useEffect(() => {
        getSettelementInvoiceDetailsFunc()
    }, [])

    return (
        <div style={{ overflow: "auto", position: "relative" }}>
            <a
                onClick={downloadPDF}
                style={{
                    position: 'absolute',
                    top: '10px',
                    right: '340px',
                    textDecoration: 'underline',
                    color: '#007BFF',
                    cursor: 'pointer',
                    fontSize: '14px',
                }}
            >
                Download Invoice
            </a>
            <div style={{
                width: '620px',
                margin: '40px auto',
                border: '1px solid rgba(215, 218, 224, 0.4)',
                borderRadius: '5px',
                boxShadow: '0 0 10px rgba(0,0,0,0.1)',
                color: "#3B3B3B",
            }}>
                <div className='myDiv'>
                    <div style={{
                        display: 'flex',
                        justifyContent: 'space-between',
                        alignItems: 'flex-start',
                        margin: '0px 30px',
                        padding: '20px 0px',
                        borderBottom: '1px solid rgba(215, 218, 224, 0.3)',
                    }}>
                        <div>
                            <img src={bhumeet} alt="bhumeet" width={"100px"} />
                        </div>
                        <div style={{ textAlign: 'right' }}>
                            {/* {settInvoice?.transaction_id ? <p style={{ margin: '0' }}>Transaction ID : {settInvoice?.transaction_id}</p> : ""} */}
                            {/* {settInvoice?.mode ? <p style={{ margin: '0' }}>Mode of Payment : {settInvoice?.mode}</p> : ""} */}
                            <p style={{ margin: '0', fontSize: "13px", }}><span style={{ fontWeight: 600, color: "#1A1C21" }}>Invoice No.</span> - {settInvoice?.invoice_number || "-"}</p>
                        </div>
                    </div>

                    <div style={{
                        display: 'flex',
                        justifyContent: 'space-between',
                        color: "#727272",
                        padding: "20px 30px"
                    }}>
                        <div>
                            <div style={{ marginBottom: '20px' }}>
                                <p style={{ margin: '0', fontWeight: 500 }}>To</p>
                                <p style={{ margin: '0', fontWeight: 'bold', color: "#1E1E1E" }}>BhuMeet Digital Pvt. Ltd.</p>
                                <p style={{ margin: '0' }}>Nashik, Maharashtra, India.</p>
                                <p style={{ margin: '0' }}>GSTIN - 27AAKCP1648Q1ZO</p>
                            </div>
                            <div>
                                <p style={{ margin: '0', fontWeight: 500 }}>From</p>
                                <p style={{ margin: '0', fontWeight: 'bold', color: "#1E1E1E" }}>{dsp?.name || "-"}</p>
                                <p style={{ margin: '0' }}>{dsp?.address || "-"}</p>
                            </div>
                        </div>
                    </div>

                    <div style={{
                        borderTop: '1px solid rgba(215, 218, 224, 0.6)',
                        paddingTop: '10px',
                        margin: "10px 30px 30px"
                    }}>
                        <p style={{ margin: '0', fontSize: "14px" }}><span style={{ fontWeight: 600, color: "#5E6470" }}>Invoice Date -</span><span style={{ color: "#5E6470" }}>{dateFormat(settInvoice?.generated_for_date, "dd mmm yyyy")}</span></p>
                    </div>

                    <div style={{
                        backgroundColor: '#D9D9D9',
                        padding: '10px 25px',
                        display: 'flex',
                        justifyContent: 'space-between',
                        fontWeight: 700,
                        color: '#1E1E1E',
                        height: "50px",
                        alignItems: "center",
                        fontSize: "18px"
                    }}>
                        <span>Payment Summary</span>
                        <span>Rates</span>
                    </div>

                    <div style={{ padding: '10px 30px 50px' }}>
                        <div style={{ marginBottom: '10px' }}>
                            <div style={{ display: 'flex', justifyContent: 'space-between', fontWeight: 500 }}>
                                <span>Service Charges</span>
                                <span>₹ {formatToIndianRupees(settInvoice?.service_amount || 0)}</span>
                            </div>
                            {settInvoice?.descriptions?.service_amount &&
                                <div style={{ fontSize: '12px', color: "#848484" }}>({settInvoice?.descriptions?.service_amount})</div>
                            }
                        </div>
                        {settInvoice?.bhumeet_coupons_amount > 0 &&
                            <div style={{ marginBottom: '10px' }}>
                                <div style={{ display: 'flex', justifyContent: 'space-between', fontWeight: 500 }}>
                                    <span>BhuMeet Coupon Redeemed</span>
                                    <span style={{}}>+ ₹ {formatToIndianRupees(settInvoice?.bhumeet_coupons_amount || 0)}</span>
                                </div>
                                {settInvoice?.descriptions?.bhumeet_coupons_amount &&
                                    <div style={{ fontSize: '12px', color: "#848484" }}>({settInvoice?.descriptions?.bhumeet_coupons_amount})</div>
                                }
                            </div>
                        }
                        {settInvoice?.incentive_from_bhumeet_amount > 0 &&
                            <div style={{ marginBottom: '10px' }}>
                                <div style={{ display: 'flex', justifyContent: 'space-between', fontWeight: 500 }}>
                                    <span>Incentive Offer</span>
                                    <span style={{}}>+ ₹ {formatToIndianRupees(settInvoice?.incentive_from_bhumeet_amount || 0)}</span>
                                </div>
                                {settInvoice?.descriptions?.incentive_from_bhumeet_amount &&
                                    <div style={{ fontSize: '12px', color: "#848484" }}>({settInvoice?.descriptions?.incentive_from_bhumeet_amount})</div>
                                }
                            </div>
                        }
                        <img src={dashLine} alt="dashLine" style={{ maxWidth: "103%" }} />
                        <div style={{
                            display: 'flex',
                            justifyContent: 'space-between',
                            marginTop: '10px',
                            fontWeight: 500
                        }}>
                            <span>Subtotal</span>
                            <span>₹ {formatToIndianRupees(settInvoice?.total_dsp_amount || 0)}</span>
                        </div>
                        {settInvoice?.platform_fees_amount > 0 &&
                            <div style={{ marginTop: '10px' }}>
                                <div style={{ display: 'flex', justifyContent: 'space-between', fontWeight: 500 }}>
                                    <span>Platform Fees
                                        {settInvoice?.descriptions?.platform_fees_amount &&
                                            <span style={{
                                                padding: '2px 5px',
                                                borderRadius: '3px',
                                                fontSize: '12px',
                                                color: "#848484"
                                            }}>
                                                ({settInvoice?.descriptions?.platform_fees_amount})
                                            </span>
                                        }
                                    </span>
                                    <span style={{}}>- ₹ {formatToIndianRupees(settInvoice?.platform_fees_amount || 0)}</span>
                                </div>
                            </div>
                        }
                        {settInvoice?.tds_charges > 0 &&
                            <div style={{ marginTop: '10px' }}>
                                <div style={{ display: 'flex', justifyContent: 'space-between', fontWeight: 500 }}>
                                    <span>TDS
                                        {settInvoice?.descriptions?.tds_charges &&
                                            <span style={{
                                                padding: '2px 5px',
                                                borderRadius: '3px',
                                                fontSize: '12px',
                                                color: "#848484"
                                            }}>
                                                {settInvoice?.descriptions?.tds_charges}
                                            </span>
                                        }
                                    </span>
                                    <span style={{}}>- ₹ {formatToIndianRupees(settInvoice?.tds_charges)}</span>
                                </div>
                            </div>
                        }
                        <div style={{
                            display: 'flex',
                            justifyContent: 'space-between',
                            // borderTop: '2px solid #000',
                            paddingTop: '10px',
                            marginTop: '10px',
                            marginBottom: '20px',
                            fontWeight: 'bold',
                            borderTop: '1px solid rgba(215, 218, 224, 0.6)',
                            borderBottom: '1px solid rgba(215, 218, 224, 0.6)',
                            paddingBottom: '10px',
                            fontSize: "18px"
                        }}>
                            <span>Total Amount</span>
                            <span>₹ {formatToIndianRupees(settInvoice?.total_dsp_amount_to_be_paid || 0)}</span>
                        </div>
                    </div>
                </div>
            </div >
        </div>
    )
}

export default DspInvoiceDetails