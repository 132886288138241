import React, { useEffect, useState } from 'react'
import Modal from '../../../ReusableComponents/Modal';
import LoaderComponent from '../../../ReusableComponents/LoaderComponent';
import DateTimePicker from '../../../ReusableComponents/DateTimePicker';
import { GLOBAL_STYLES, STATUS, AEROMEGH_SERVICES } from '../../../appConstants';
import { Button } from '../../../Components/Helper';
import Tippy from '@tippyjs/react';
import CloseIcon from "../../../assets/closebtn.svg"
import Filter from "../../../assets/filter.svg"
import { reduceLongName } from '../../../ReusableFunctions/reusableFunctions';
import SingleFilter from '../../../features/MyRequests/SubComponents/FilterRequest/SingleFilter';
import CustomTooltip from '../../../ReusableComponents/CustomTooltip';
import Popup from '../../../ReusableFunctions/Popup';
import { getAllDSPs } from '../../../apis/adminAPIs/dsp';
import dateFormat from 'dateformat';

const AdminFilterRequest = ({ user, open, onClose, callback, setOpenFilter, dateTime, screenWidth, loading, setLoading, isFromUserDetails }) => {

    const [filters, setFilter] = useState({ services: [], statuses: [], dsps: [], locations: [], isDspActive: true })
    const [isScrollable, setIsScrollable] = useState(false);
    const [dsps, setDsps] = useState([])
    const [appliedFilters, setappliedFilters] = useState([
        {
            name: `DspStatus-${filters.isDspActive ? "Active" : filters.isDspActive === null ? "Both" : "Inactive"}`,
            type: "DspStatus"
        },
        {
            name: `Date: ${dateFormat(dateTime?.fromDate, "dd/mm/yyyy hh:MM TT")} to ${dateFormat(dateTime?.toDate, "dd/mm/yyyy hh:MM TT")}`,
            type: `date`
        }
    ]);
    const [chipInputSize, setChipInputSize] = useState(0);
    const [fromDateToDate, setFromDateToDate] = useState({
        fromDate: dateTime ? dateTime.fromDate : null,
        toDate: dateTime ? dateTime.toDate : null
    })

    const handleOnChangeServices = (e) => {
        const value = e.target.value
        let newServices = filters.services
        if (e.target.checked) {
            newServices = [...filters.services, value]
        } else {
            newServices = newServices.filter(service => service !== value)
        }
        newServices = Array.from(new Set(newServices))
        setFilter(prev => ({ ...prev, services: newServices }))
    }

    const handleOnChangeStatus = (e) => {
        const value = e.target.value
        let newStatuses = filters.statuses
        if (e.target.checked) {
            newStatuses = [...filters.statuses, value]
        } else {
            newStatuses = newStatuses.filter(status => status !== value)
        }
        newStatuses = Array.from(new Set(newStatuses))
        setFilter(prev => ({ ...prev, statuses: newStatuses }))
    }

    const handleOnChangeLocation = (e) => {
        const value = e.target.value
        let dsps = filters.dsps
        if (e.target.checked) {
            dsps = [...filters.dsps, value]
        } else {
            dsps = dsps.filter(dsp => dsp !== value)
        }
        dsps = Array.from(new Set(dsps))
        setFilter(prev => ({ ...prev, dsps: dsps }))
    }

    const handleDspStatusChange = (e) => {
        const value = e.target.value === 'true' ? true : e.target.value === 'false' ? false : null;
        setFilter(prev => ({ ...prev, isDspActive: value }));
    };

    const getDspsFunc = async () => {
        try {
            const dsps = await getAllDSPs()
            console.log('dsps:', dsps?.dsps)
            setDsps(dsps?.dsps)
            setLoading(false)
        } catch (err) {
            setLoading(false)
            Popup.alert("Error", err, "ERROR")
        }
    }

    const updateFilter = (key, filter) => {
        let newValues = filters[key].filter(value => value !== (key === 'dsps' ? filter.id : filter.name));
        newValues = Array.from(new Set(newValues));
        setFilter(prev => ({ ...prev, [key]: newValues }));
        setappliedFilters(prev => prev.filter(f => {
            if (f.type === 'dsp') {
                return f.id !== filter.id;
            } else {
                return f.name !== filter.name;
            }
        }));
        callback({ ...filters, [key]: newValues }, fromDateToDate);
    };

    const clearFilter = async (filter) => {
        console.log('filter:', filter)
        switch (filter.type) {
            case 'service':
                updateFilter('services', filter);
                break;
            case 'status':
                updateFilter('statuses', filter);
                break;
            case 'dsp':
                updateFilter('dsps', filter);
                break;
            case 'DspStatus':
                setFilter((prev) => ({
                    ...prev,
                    isDspActive: null
                }));
                setappliedFilters(appliedFilters.filter((af) => af.type !== "DspStatus"))
                callback(filters, fromDateToDate);
                break;
            case 'date':
                setFromDateToDate({
                    fromDate: null,
                    toDate: null
                })
                setappliedFilters(appliedFilters.filter((af) => af.type !== 'date'))
                callback(filters, {
                    fromDate: null,
                    toDate: null
                });
            default:
                break;
        }
    };

    useEffect(() => {
        const container = document.getElementsByClassName('filetrs-container')[0];
        if (container) {
            if (container.scrollWidth > container.clientWidth) {
                setIsScrollable(true);
            } else {
                setIsScrollable(false);
            }
        }
    }, [appliedFilters]);

    useEffect(() => {
        setChipInputSize(getChipInputSize(screenWidth))
    }, [screenWidth])

    const getChipInputSize = (screenSize) => {
        if (isFromUserDetails) {
            switch (screenSize) {
                case 'x-large':
                    return 6;
                case 'large':
                    return 5;
                case 'medium':
                    return 3;
                case 'small':
                    return 2;
                default:
                    return 6;
            }
        } else {
            switch (screenSize) {
                case 'x-large':
                    return 6;
                case 'large':
                    return 4;
                case 'medium':
                    return 3;
                case 'small':
                    return 2;
                default:
                    return 6;
            }
        }
    }


    useEffect(() => {
        getDspsFunc()
    }, [])

    return (
        <>
            <Modal header={"Filter"} contentStyle={{ width: "850px", height: "630px" }} headingStyle={{ fontSize: "18px", fontWeight: 600, }} headerContainerStyle={{ padding: "20px" }} open={open} onClose={onClose}>
                <div style={{ width: "100%", borderTop: "0.5px solid lightgray" }}></div>
                {loading ?
                    <LoaderComponent /> :
                    <div style={{ fontSize: GLOBAL_STYLES.FONT_SIZE_REGULAR, height: "calc(100% - 120px)", display: "flex", flexDirection: "column", justifyContent: "space-between" }}>
                        <div style={{ padding: "20px 30px", display: "flex", justifyContent: "space-between", alignItems: "center", width: "100%", gap: "10%" }}>
                            <DateTimePicker
                                title="From"
                                hideNxt={new Date()}
                                selectedDateTime={fromDateToDate.fromDate}
                                onChange={(date) => {
                                    setFromDateToDate({
                                        ...fromDateToDate,
                                        fromDate: date
                                    })
                                }}
                            />
                            <DateTimePicker
                                title="To"
                                hidePrev={fromDateToDate.fromDate}
                                selectedDateTime={fromDateToDate.toDate}
                                onChange={(date) => {
                                    if (new Date(fromDateToDate.fromDate) < new Date(date)) {
                                        setFromDateToDate({
                                            ...fromDateToDate,
                                            toDate: date
                                        })
                                    } else {
                                        setFromDateToDate({
                                            ...fromDateToDate,
                                            toDate: fromDateToDate.toDate ? new Date(fromDateToDate.toDate) : new Date()
                                        })
                                    }

                                }}
                                isDisable={!(fromDateToDate.fromDate)}
                            />
                        </div>
                        {/* DSP Status Filter Radio Buttons */}
                        <div style={{ fontSize: "16px", fontWeight: 600, color: "#848484", padding: "0px 30px" }}>Type of DSP</div>
                        <div style={{ padding: "10px 30px", display: "flex", justifyContent: "space-between", alignItems: "center", width: "60%" }}>
                            <label style={{ fontSize: "16px", fontWeight: 500, display: "flex", alignItems: "center", gap: "10px" }}>
                                <input
                                    type="radio"
                                    name="dspStatus"
                                    value={true}
                                    checked={filters?.isDspActive === true}
                                    onChange={handleDspStatusChange}
                                    style={{ cursor: "pointer" }}
                                />
                                Active DSPs
                            </label>
                            <label style={{ fontSize: "16px", fontWeight: 500, display: "flex", alignItems: "center", gap: "10px" }}>
                                <input
                                    type="radio"
                                    name="dspStatus"
                                    value={false}
                                    checked={filters?.isDspActive === false}
                                    onChange={handleDspStatusChange}
                                    style={{ cursor: "pointer" }}
                                />
                                Inactive DSPs
                            </label>
                            <label style={{ fontSize: "16px", fontWeight: 500, display: "flex", alignItems: "center", gap: "10px" }}>
                                <input
                                    type="radio"
                                    name="dspStatus"
                                    value={null}
                                    checked={filters?.isDspActive === null}
                                    onChange={handleDspStatusChange}
                                    style={{ cursor: "pointer" }}
                                />
                                Both
                            </label>
                        </div>
                        <div style={{ padding: "20px 30px", display: "grid", gridTemplateColumns: "1fr 1fr 2fr", height: "65%", rowGap: "10px", fontSize: '14px', columnGap: "10px" }}>
                            <div style={{ fontSize: "16px", fontWeight: 500 }}>Services</div>
                            <div style={{ fontSize: "16px", fontWeight: 500 }}>Status</div>
                            <div style={{ fontSize: "16px", fontWeight: 500 }}>DSPs</div>
                            <div style={{ fontSize: "16px", fontSize: '14px', display: "flex", flexDirection: "column", rowGap: "23px", height: "100%", color: "#3B3B3B", fontWeight: 500, maxHeight: "240px" }}>
                                {Object.keys(AEROMEGH_SERVICES).slice(0, 2).map((key) => {
                                    return <SingleFilter
                                        isChecked={filters.services.filter(status => status == AEROMEGH_SERVICES[key]).length > 0}
                                        onChange={handleOnChangeServices}
                                        value={AEROMEGH_SERVICES[key]}
                                        title={AEROMEGH_SERVICES[key].split(' ')[0]}
                                    />
                                })}
                            </div>
                            <div style={{ fontSize: '14px', display: "flex", flexDirection: "column", rowGap: "23px", color: "#3B3B3B", fontWeight: 500, maxHeight: "240px", overflowY: "auto" }}>
                                {Object.keys(STATUS).map((key) => {
                                    return <SingleFilter
                                        isChecked={filters.statuses.filter(status => status == STATUS[key]).length > 0}
                                        onChange={handleOnChangeStatus}
                                        value={STATUS[key]}
                                        title={STATUS[key]}
                                    />
                                })}
                            </div>
                            <div style={{ fontSize: '14px', display: "flex", flexDirection: "column", rowGap: "23px", overflowY: "auto", height: "100%", color: "#3B3B3B", fontWeight: 500, maxHeight: "240px" }}>
                                {dsps?.map(dsp => <div style={{ display: "flex", alignItems: "center", gap: "10px" }}>
                                    <div style={{ width: "25px", display: "flex", alignItems: "center", justifyContent: "center" }}>
                                        <input
                                            checked={filters.dsps.filter(loc => loc === dsp.dsp_id).length > 0}
                                            onChange={handleOnChangeLocation}
                                            value={dsp.dsp_id}
                                            type={"checkbox"}
                                            className="input-checkbox"
                                        />
                                    </div>
                                    <Tippy content={dsp.dsp_name}>
                                        <span style={{ lineHeight: 1.1, fontSize: "15px", fontWeight: 500 }}>{reduceLongName(dsp.dsp_name, 40)}</span>
                                    </Tippy>
                                </div>)}
                            </div>
                        </div>
                        <div style={{ display: "flex", justifyContent: "space-between", padding: "20px 30px", borderTop: '0.5px solid #BFC8D9' }}>
                            <div onClick={async () => {
                                setFilter({ services: [], statuses: [], dsps: [], isDspActive: null })
                                setFromDateToDate({
                                    fromDate: null,
                                    toDate: null
                                })
                                setappliedFilters([]);
                            }} style={{ width: "100px", color: GLOBAL_STYLES.BG_ACTIVE, fontWeight: 600, fontSize: "14px", cursor: 'pointer', display: "flex", alignItems: "center", justifyContent: "start" }}>
                                Clear all
                            </div>
                            <Button
                                text={"Apply"}
                                style={{ width: "130px", height: "38px", fontSize: "15px", marginRight: "10px" }}
                                onClick={async () => {

                                    if (fromDateToDate.fromDate && fromDateToDate.toDate) callback(filters, fromDateToDate)
                                    else callback(filters)

                                    onClose()
                                    let selectedServices = filters.services.map((ser) => ({
                                        name: ser,
                                        type: "service"
                                    }))
                                    let selectedStatus = filters.statuses.map((st) => ({
                                        name: st,
                                        type: "status"
                                    }))
                                    let selectedLocations = filters?.dsps?.map(dspId => {
                                        const dsp = dsps.find(dsp => dsp.dsp_id === dspId)
                                        return { name: dsp?.dsp_name, type: "dsp" };
                                    });
                                    if (filters.isDspActive !== null && filters.isDspActive !== undefined && fromDateToDate.fromDate && fromDateToDate.toDate) {
                                        setappliedFilters((prev) => [...selectedServices, ...selectedStatus, ...selectedLocations, {
                                            name: `DspStatus-${filters.isDspActive ? "Active" : filters.isDspActive === null ? "Both" : "Inactive"}`,
                                            type: `DspStatus`
                                        }, {
                                            name: `Date: ${dateFormat(fromDateToDate.fromDate, "dd/mm/yyyy hh:MM TT")} to ${dateFormat(fromDateToDate.toDate, "dd/mm/yyyy hh:MM TT")}`,
                                            type: 'date'
                                        }]);
                                    } else {
                                        setappliedFilters([...selectedServices, ...selectedStatus, ...selectedLocations]);
                                    }

                                }}
                            />
                        </div>
                    </div>
                }
            </Modal>
            <div style={{ display: "flex " }}>
                <div className='flex justify-end gap-2 mr-1 '>
                    <div className='flex gap-2  items-center'>
                        {isScrollable &&
                            <div style={{ color: "#0F5EEF", cursor: "pointer", marginRight: "10px" }} onClick={() => {
                                const container = document.getElementsByClassName('filetrs-container')[0];
                                if (container) {
                                    container.scrollBy({
                                        left: -100,
                                        behavior: 'smooth'
                                    });
                                }
                            }}>
                                {"<"}
                            </div>
                        }
                        <div className='filetrs-container' style={{ display: "flex", gap: "10px", overflow: "auto", maxWidth: "1000px", minWidth: "100px" }}>
                            {
                                appliedFilters?.length > 0 && appliedFilters.map((filter) => {
                                    return (
                                        <div
                                            style={{
                                                display: "flex",
                                                fontSize: "12px",
                                                justifyContent: "center",
                                                alignItems: "center",
                                                gap: "5px",
                                                background: "#0F5EFF1A",
                                                border: "1px solid #0F5EFF",
                                                height: "30px",
                                                width: "100px",
                                                borderRadius: "5px",
                                                color: "#0F5EFF",
                                                padding: "0 15px"
                                            }}
                                        >
                                            <Tippy allowHTML={true} content={<CustomTooltip content={filter.name} customStyle={{ maxWidth: "230px" }} />} placement="top-start">
                                                <span >{filter.name?.split(" ")[0].length > 7 ? reduceLongName(filter.name?.split(" ")[0], 7) : filter.name?.split(" ")[0]}</span>
                                            </Tippy>
                                            <img className='cursor-pointer' onClick={() => clearFilter(filter)} src={CloseIcon} alt='closebtn' />
                                        </div>
                                    )
                                })
                            }
                        </div>
                        {isScrollable &&
                            <div style={{ color: "#0F5EEF", cursor: "pointer", margin: "0px 10px" }} onClick={() => {
                                const container = document.getElementsByClassName('filetrs-container')[0];
                                if (container) {
                                    container.scrollBy({
                                        left: 100,
                                        behavior: 'smooth'
                                    });
                                }
                            }}>
                                {">"}
                            </div>
                        }
                    </div>
                </div>
                {
                    <Button
                        innerClassName={"filter-btn-inner"}
                        iconStyle={{ width: "18px", marginRight: "10px" }}
                        text={"Filter"}
                        icon={Filter}
                        className={"filter-btn"}
                        onClick={() => {
                            setOpenFilter(true)
                        }}
                        textClassName={"user-details-filter-text"}
                    />
                }
            </div>
        </>
    )
}

export default AdminFilterRequest