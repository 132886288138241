import { SERVER_URLS } from "../../appConstants";
import { getToken } from "../authentication";

export const getAllRequests = ({ userId, filters, pageSize, pageNumber, searchQuery, fromDate, toDate, pilotId, isOrderByServiceTime, is_dsp_active }) => {
    return new Promise(async (resolve, reject) => {
        try {
            let data;
            const token = getToken();
            const response = await fetch(
                `${SERVER_URLS.BACKEND_SERVER}/aggregator/request?pageSize=${pageSize || ""}&pageNumber=${pageNumber || ""}&searchQuery=${searchQuery || ""}` +
                `${(filters.isDspActive != null || filters.isDspActive != undefined) ? `&isDspActive=${filters.isDspActive}` : ""}` +
                `${filters?.services?.length > 0 ? `&services=${encodeURIComponent(JSON.stringify(filters.services))}` : ""}` +
                `${filters?.dsps?.length > 0 ? `&dsps=${encodeURIComponent(JSON.stringify(filters.dsps))}` : ""}` +
                `${filters?.statuses?.length > 0 ? `&statuses=${encodeURIComponent(JSON.stringify(filters.statuses))}` : ""}` +
                `${filters?.locations?.length > 0 ? `&locations=${encodeURIComponent(JSON.stringify(filters.locations))}` : ""}` +
                `${userId ? `&userId=${userId}` : ""}` +
                `${fromDate ? `&fromDate=${fromDate}` : ""}` +
                `${toDate ? `&toDate=${toDate}` : ""}` +
                `${pilotId ? `&pilotId=${pilotId}` : ""}` +
                `${isOrderByServiceTime ? `&isOrderByServiceTime=true` : ""}` +
                `${is_dsp_active ? `&is_dsp_active=true` : ""}`,
                {
                    method: "GET",
                    headers: {
                        Authorization: `Bearer ${token}`,
                    },
                }
            );

            if (response.status === 204) {
                resolve({ requests: [] });
            } else {
                data = await response.json();
            }
            if (response.ok && response.status === 200) {
                resolve({ requests: data.requests });
            } else if (response.status === 204) {
                resolve({ requests: [] });
            } else {
                reject(data?.message || "Something went wrong while getting requests.");
            }
        } catch (error) {
            reject("Something went wrong while getting requests.");
        }
    });
}

export const getAllRequestsCount = ({ fromDate, toDate, is_dsp_active }) => {
    return new Promise(async (resolve, reject) => {
        try {
            const token = getToken();
            const response = await fetch(
                SERVER_URLS.BACKEND_SERVER + `/aggregator/request/getRequestsCount?temp=true${is_dsp_active ? `&is_dsp_active=true` : ""}`,
                {
                    method: "GET",
                    headers: {
                        Authorization: `Bearer ${token}`,
                    },
                }
            );
            const data = await response.json();
            if (response.ok) {
                resolve(data.requestsCount);
            } else {
                reject(
                    data?.message || "Something went wrong while getting requests."
                );
            }
        } catch (error) {
            reject("Something went wrong while getting requests.");
        }
    });
}