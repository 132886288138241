import React from 'react'
import cardcover from '../../../assets/cardCover.png'
import Paid from "../../../assets/paid.svg"
import InProgress from "../../../assets/inprogress.svg"
import Received from "../../../assets/recieved.svg"
import accepted from "../../../assets/accepted.svg"
import cancle from "../../../assets/cancle.svg"
import outForService from "../../../assets/outForService.svg"
import reschedule from "../../../assets/reschedule.svg"
import Completed from "../../../assets/Completed.svg"
import { STATUS } from '../../../appConstants'

const AdminStatusCountCard = ({ request }) => {
    const pickIcon = (status) => {
        switch (status) {
            case STATUS.ACCEPTED:
                return accepted;

            case STATUS.OUT_FOR_SERVICE:
                return outForService;

            case STATUS.COMPLETED:
                return Completed;

            case STATUS.INPROGRESS:
                return InProgress;

            case STATUS.RESCHEDULED:
                return reschedule;

            case STATUS.PAID:
                return Paid;

            case STATUS.PLACED:
                return Received;

            case STATUS.CANCELLED:
                return cancle;
        }
    }
    return (
        <div style={{ position: "relative", padding: "12px", boxShadow: '0px 0px 20px 1px rgba(117, 117, 117, 0.15)', width: "180px", height: "75px", borderRadius: "8px", display: "flex", flexDirection: "column", justifyContent: "space-between", overflow: 'hidden' }}>
            <img src={cardcover} style={{ position: "absolute", left: 0, top: 0, objectFit: 'cover', width: "100%", height: "100%", scale: '1.2', zIndex: 1 }} />
            <div style={{ position: 'absolute', width: "100%", height: "100%", zIndex: 2, left: 0, top: 0, padding: "12px", display: "flex", justifyContent: "space-between", overflow: 'hidden' }}>
                <div>
                    <img className='request-progress-card-icon' src={pickIcon(request?.status)} />
                </div>
                <div style={{ display: "flex", flexDirection: "column", alignItems: "end", marginTop: "" }}>
                    <div className='request-progress-card-data' >{request?.count}</div>

                    <div style={{ fontSize: '14px', color: "#3B3B3B", fontWeight: 500 }}>{request?.status}</div>
                </div>
            </div>

        </div>
    )
}

export default AdminStatusCountCard