import React, { useEffect, useState } from 'react'
import MainContentWrapper from '../../Components/MainContentWrapper'
import DashBoardCard from '../../features/HomePage/SubComponents/DashBoardCard/DashBoardCard'
import { PDRL_ADMIN_ROUTES } from '../../routes'
import { addLeadingZero } from '../../ReusableFunctions/reusableFunctions'
import customersCardIcon from "../../assets/customersCardIcon.svg"
import teamsCardIcon from "../../assets/teamsCardIcon.svg"
import serviceCardIcon from "../../assets/serviceCardIcon.svg"
import Loading from '../../ReusableFunctions/Loader'
import Popup from '../../ReusableFunctions/Popup'
import { getCustSuccDashboardOverview } from '../../apis/BhuMeetTeams/CustomerSuccessEngineer/dashboard'

const CustSuccessDashboard = () => {
    const [dashBoardData, setDashBoardData] = useState({})

    const getAdminDashboardOverviewFunc = async () => {
        Loading.set(true)
        try {
            const overview = await getCustSuccDashboardOverview()
            console.log('overview:', overview)
            setDashBoardData(overview?.dashboardOverview)
            Loading.set(false)
        } catch (err) {
            Loading.set(false)
            Popup.alert("Error", err, 'ERROR')
        }
    }

    useEffect(() => {
        getAdminDashboardOverviewFunc()
        localStorage.setItem('dsp_id', "admin")
    }, [])

    return (
        <MainContentWrapper>
            <div className="dashboard-first-section-second-div" style={{ width: "100%" }} >
                <div className="dashboard-first-section-second-div-one" style={{ display: "grid", gridTemplateColumns: "1fr 1fr 1fr 1fr 1fr" }} >
                    {/* ---- DSPs ---- */}
                    <DashBoardCard
                        loading={false}
                        view={PDRL_ADMIN_ROUTES.DRONE_SERVICE_PROVIDERS}
                        title="DSPs"
                        data={addLeadingZero(dashBoardData?.dsps)}
                        icon={customersCardIcon}
                    />

                    {/* ---- Users ---- */}
                    <DashBoardCard
                        loading={false}
                        view={PDRL_ADMIN_ROUTES.USERS}
                        title="Users"
                        data={addLeadingZero(dashBoardData?.users)}
                        icon={teamsCardIcon}
                    />

                    {/* ---- Requests ---- */}
                    <DashBoardCard
                        loading={false}
                        view={PDRL_ADMIN_ROUTES.REQUESTS}
                        title="Requests"
                        data={addLeadingZero(dashBoardData?.totalRequest)}
                        icon={serviceCardIcon}
                    />
                </div>
            </div>
        </MainContentWrapper>
    )
}

export default CustSuccessDashboard