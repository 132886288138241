import React, { useState } from 'react'
import DateTimePicker from '../../../ReusableComponents/DateTimePicker';
import { GLOBAL_STYLES } from '../../../appConstants';
import { Button } from '../../../Components/Helper';

const UsersFilter = ({ filter, onFilterChange, toMinDate }) => {
    const [usersFilter, setUsersFilter] = useState(filter);

    const handleFilterChange = (updatedFilter) => {
        setUsersFilter(updatedFilter);
    };

    return (
        <div style={{ display: "flex", gap: "60px", width: "100%", justifyContent: "space-between", alignItems: "end" }}>
            <div style={{ display: "flex", justifyContent: "space-between", alignItems: "center", width: "100%", gap: "10%" }}>
                <DateTimePicker
                    title="From"
                    hideNxt={new Date()}
                    selectedDateTime={usersFilter.fromDate}
                    onChange={(date) => {
                        handleFilterChange({
                            ...usersFilter,
                            fromDate: new Date(date).toISOString()
                        });
                    }}
                    containerWidth={"144px"}
                />
                <DateTimePicker
                    title="To"
                    hidePrev={usersFilter.fromDate}
                    selectedDateTime={usersFilter.toDate}
                    onChange={(date) => {
                        if (new Date(usersFilter.fromDate) < new Date(date)) {
                            handleFilterChange({
                                ...usersFilter,
                                toDate: new Date(date).toISOString()
                            });
                        } else {
                            handleFilterChange({
                                ...usersFilter,
                                toDate: usersFilter.toDate ? new Date(usersFilter.toDate) : new Date()
                            })
                        }
                    }}
                    isDisable={!(usersFilter.fromDate)}
                    containerWidth={"144px"}
                    toMinDate={usersFilter.fromDate}
                />
            </div>
            <div style={{ display: "flex", justifyContent: "space-between", height: '38px', gap: "20px" }}>
                <div
                    onClick={async () => {
                        setUsersFilter({
                            fromDate: null,
                            toDate: null
                        })
                        onFilterChange({
                            fromDate: null,
                            toDate: null
                        })
                    }} style={{ width: "54px", height: "38px", color: GLOBAL_STYLES.BG_ACTIVE, fontWeight: 600, fontSize: "14px", cursor: 'pointer', display: "flex", alignItems: "center", justifyContent: "start" }}>
                    Clear all
                </div>
                <Button
                    text={"Apply"}
                    style={{ width: "66px", height: "38px", fontSize: "15px" }}
                    onClick={() => {
                        onFilterChange(usersFilter)
                    }}
                />
            </div>
        </div>
    )
}

export default UsersFilter