import MyLocations from "./features/MyLocations/MyLocations"
import MyServices from "./features/MyServices/MyServices"
import MyRequests from "./features/MyRequests/MyRequests"
import AddService from "./features/MyServices/SubComponents/AddService/AddService"
import { UsersList } from "./Components/UserDetails/Users"
import { UserDetails } from "./Components/UserDetails/UserDetails"
import { Fields } from "./Components/Fields"
import AddDronePilot from "./features/DronePilots/SubComponents/AddDronePilot"
import DronePilots from "./features/DronePilots/DronePilots"
import ManageTeam from "./features/Operators/ManageTeam"
import MyDrones from "./features/MyDrones/MyDrones"
import ViewRequest from "./features/MyRequests/SubComponents/ViewRequest/ViewRequest"
import AdminDashBoard from "./AdminComponents/AdminDashBoard/AdminDashBoard"
import DroneServiceProvider from "./AdminComponents/DroneServiceProviders/DroneServiceProviders"
import Users from "./AdminComponents/Users/Users"
import Requests from "./AdminComponents/Requests/Requests"
import Locations from "./AdminComponents/Locations/Locations"
import Revenue from "./features/Revenue/Revenue"
import Manufacturers from "./AdminComponents/Manufacturers/Manufacturers"
import RequestDetails from "./features/MyRequests/RequestDetails"
import Crops from "./AdminComponents/Crops/Crops"
import AdminCoupons from "./AdminComponents/Coupons/AdminCoupons"
import ViewLocationsOnMap from "./AdminComponents/Locations/ViewLocationsOnMap"
import AllCoupons from './features/Coupon/AllCoupons'
import CreateRequest from "./features/MyRequests/CreateRequest"
import MyWallet from "./features/Wallet/MyWallet"
import SupportEmails from "./AdminComponents/SupportEmails/SupportEmails"
import PaymentInvoice from "./AdminComponents/Finance/subComponents/PaymentInvoice.js"
import DspInvoiceDetails from "./features/Invoices/DspInvoiceDetails"
import ManageAdminTeam from "./AdminComponents/Teams/ManageAdminTeam"
import FinanceDashboard from "./BhuMeetTeams/BhuMeetFinance/FinanceDashboard"
import CustSuccessDashboard from "./BhuMeetTeams/BhuMeetCustomerSuccess/CustSuccessDashboard"
import Profile from "./features/Profile/Profile"
import HomePage from "./features/HomePage/HomePage"
import OperationsDashboard from "./BhuMeetTeams/Operations/OperationsDashboard"
import DspWallet from './AdminComponents/Finance/wallets/DspWallet.js'
import DspInvoices from "./AdminComponents/Finance/DSPInvoices/index.js"
import UserInvoices from "./AdminComponents/Finance/UserInvoices/UserInvoices.js"
import UserInvoiceDetails from "./AdminComponents/Finance/UserInvoices/UserInvoiceDetails.js"
import RevenueDashboard from "./BhuMeetTeams/BhuMeetFinance/RevenueDashboard.js"
import ViewUserDetails from "./AdminComponents/Users/SubComponents/ViewUserDetails.js"
import ViewRequestDetails from "./AdminComponents/Requests/SubComponents/ViewRequestDetails.js"


const ROUTES = {
    PROFILE: "/profile",
    MYSERVICES: "/manageServices",
    MYLOCATIONS: "/manageLocations",
    MYREQUESTS: "/manageRequests",
    REQUESTDETAILS: "/requestDetails",
    ADD_SERVICE: "/service",
    CUSTOMERS: "/customers",
    CUSTOMER_DETAILS: "/customer",
    CUSTOMER_FIELDS: "/customer/:userId/fields",
    ADD_DRONE_PILOT: "/addDronePilot",
    DRONE_PILOTS: "/dronePilots",
    MANAGE_TEAM: "/manageTeams",
    MYREVENUE: "/revenue",
    MYDRONE: "/manageDrones",
    VIEWREQUEST: "/viewRequest",
    REVENUE: "/revenue",
    COUPON: '/coupons',
    CREATEREQUEST: '/createRequest',
    WALLET: "/wallet",
    PAYMENTINVOICE: '/invoice'
}

const PDRL_FINANCE_ROUTES = {
    FINANCE_DASHBOARD: "/bhumeet_team/finance/dashboard",
    DSP_INVOICES: '/bhumeet_team/finance/dsp/invoices',
    USER_INVOICES: '/bhumeet_team/finance/user/invoices',
    USER_INVOICE_DETAILS: '/bhumeet_team/finance/user/invoiceDetails',
    REVENUE_DASHBOARD: '/bhumeet_team/finance/revenueDashboard',
    PAYMENTINVOICE: '/paymentInvoice',
}

const PDRL_SUCCESS_ENGI_ROUTES = {
    SUCCESS_ENGI_DASHBOARD: "/bhumeet_team/cse/dashboard",
    DRONE_SERVICE_PROVIDERS: "/droneServiceProviders",
    USERS: "/users",
    USERDETAILS: "/userDetails",
    REQUESTS: "/requests",
    REQUESTDETAILS:"/requestDetails"
}

const PDRL_ADMIN_ROUTES = {
    ADMIN_DASHBOARD: "/adminDashboard",
    LOCATIONS: "/locations",
    MANUFACTURES: '/manufacturers',
    CROPS: '/crops',
    COUPONS: '/adminCoupons',
    VIEWLOCATIONSONMAP: '/locations/map',
    SUPPORTEMAILS: '/supportEmails',
    DSPWALLET: '/dspWallet',
    TEAMS: '/teams',
    OPEARTIONSDASHBOARD: '/operations',
    ...PDRL_FINANCE_ROUTES,
    ...PDRL_SUCCESS_ENGI_ROUTES
}

const PDRL_FINANCE_COMPONENTS = [
    {
        path: PDRL_FINANCE_ROUTES.FINANCE_DASHBOARD,
        element: <FinanceDashboard />
    },
    {
        path: PDRL_FINANCE_ROUTES.DSP_INVOICES,
        element: <DspInvoices />
    },
    {
        path: PDRL_FINANCE_ROUTES.USER_INVOICES,
        element: <UserInvoices />
    },
    {
        path: PDRL_FINANCE_ROUTES.USER_INVOICE_DETAILS,
        element: <UserInvoiceDetails />
    },
    {
        path: PDRL_FINANCE_ROUTES.REVENUE_DASHBOARD,
        element: <RevenueDashboard />
    },
    {
        path: PDRL_FINANCE_ROUTES.PAYMENTINVOICE,
        element: <PaymentInvoice />
    },
]

const PDRL_SUCCESS_ENGI_COMPONENTS = [
    {
        path: PDRL_SUCCESS_ENGI_ROUTES.SUCCESS_ENGI_DASHBOARD,
        element: <CustSuccessDashboard />
    },
    {
        path: PDRL_ADMIN_ROUTES.DRONE_SERVICE_PROVIDERS,
        element: <DroneServiceProvider />
    },
    {
        path: PDRL_ADMIN_ROUTES.USERS,
        element: <Users />
    },
    {
        path: PDRL_ADMIN_ROUTES.REQUESTS,
        element: <Requests />
    },
    {
        path: PDRL_SUCCESS_ENGI_ROUTES.USERDETAILS,
        element: < ViewUserDetails />
    },
    {
        path: PDRL_SUCCESS_ENGI_ROUTES.REQUESTDETAILS,
        element: <ViewRequestDetails />
    }
]

const PDRL_ADMIN_ROUTES_COMPONENTS = [
    {
        path: PDRL_ADMIN_ROUTES.ADMIN_DASHBOARD,
        element: <AdminDashBoard />
    },
    {
        path: PDRL_ADMIN_ROUTES.LOCATIONS,
        element: <Locations />
    },
    {
        path: PDRL_ADMIN_ROUTES.MANUFACTURES,
        element: <Manufacturers />
    },
    {
        path: PDRL_ADMIN_ROUTES.CROPS,
        element: <Crops />
    },
    {
        path: PDRL_ADMIN_ROUTES.TEAMS,
        element: <ManageAdminTeam />
    },
    {
        path: PDRL_ADMIN_ROUTES.COUPONS,
        element: <AdminCoupons />
    },
    {
        path: PDRL_ADMIN_ROUTES.VIEWLOCATIONSONMAP,
        element: <ViewLocationsOnMap />
    },
    {
        path: PDRL_ADMIN_ROUTES.DSPWALLET,
        element: <DspWallet />
    },
    {
        path: PDRL_ADMIN_ROUTES.SUPPORTEMAILS,
        element: <SupportEmails />
    },
    {
        path: PDRL_ADMIN_ROUTES.OPEARTIONSDASHBOARD,
        element: <OperationsDashboard />
    },
    ...PDRL_FINANCE_COMPONENTS,
    ...PDRL_SUCCESS_ENGI_COMPONENTS
]

const ALL_ROUTES_ARRAY = [
    {
        path: "/",
        element: <HomePage />
    },
    {
        path: ROUTES.MYSERVICES,
        element: <MyServices />
    }, {
        path: ROUTES.MYLOCATIONS,
        element: <MyLocations />
    }, {
        path: ROUTES.MYREQUESTS,
        element: <MyRequests />
    }, {
        path: ROUTES.REQUESTDETAILS,
        element: <RequestDetails />
    }, {
        path: ROUTES.ADD_SERVICE,
        element: <AddService />
    },
    {
        path: ROUTES.CUSTOMERS,
        element: <UsersList />
    },
    {
        path: ROUTES.CUSTOMER_DETAILS,
        element: <UserDetails />
    },
    {
        path: ROUTES.CUSTOMER_FIELDS,
        element: <Fields />
    },
    {
        path: ROUTES.DRONE_PILOTS,
        element: <DronePilots />
    },
    {
        path: ROUTES.ADD_DRONE_PILOT,
        element: <AddDronePilot />
    },
    {
        path: ROUTES.MANAGE_TEAM,
        element: <ManageTeam />
    },
    {
        path: ROUTES.MYDRONE,
        element: <MyDrones />
    },
    {
        path: ROUTES.VIEWREQUEST,
        element: <ViewRequest />
    },
    {
        path: ROUTES.REVENUE,
        element: <Revenue />
    },
    {
        path: ROUTES.COUPON,
        element: <AllCoupons />
    },
    {
        path: ROUTES.CREATEREQUEST,
        element: <CreateRequest />
    },
    {
        path: ROUTES.WALLET,
        element: <MyWallet />
    },
    {
        path: ROUTES.PAYMENTINVOICE,
        element: <DspInvoiceDetails />
    },
]

export {
    ROUTES,
    ALL_ROUTES_ARRAY,
    PDRL_ADMIN_ROUTES,
    PDRL_SUCCESS_ENGI_ROUTES,
    PDRL_ADMIN_ROUTES_COMPONENTS,
    PDRL_SUCCESS_ENGI_COMPONENTS,
    PDRL_FINANCE_COMPONENTS,
    PDRL_FINANCE_ROUTES
}