import { SERVER_URLS } from "../../../appConstants";
import { buildUrl } from "../../../ReusableFunctions/reusableFunctions";
import { getToken } from "../../authentication";


const DSP_INVOICES_BASE_URL = `${SERVER_URLS.BACKEND_SERVER}/bhumeet_team/finance/dsp/invoices`
export const getDSPSettlementInvoices = async (dspId, fromDate, toDate, pageSize, pageNumber, statuses, isTdsFilter, isIncentiveFilter, isPlatformFeeFilter) => {
    try {
        const token = getToken();
        const url = buildUrl(DSP_INVOICES_BASE_URL, {
            fromDate: fromDate ? fromDate : undefined,
            toDate: toDate ? toDate : undefined,
            pageSize: pageSize && pageNumber ? pageSize : undefined,
            pageNumber: pageSize && pageNumber ? pageNumber : undefined,
            dspId: dspId ? dspId : undefined,
            statuses: statuses ? statuses.toString() : undefined,
            isTdsFilter,
            isIncentiveFilter,
            isPlatformFeeFilter
        });
        const response = await fetch(
            url,
            {
                method: "GET",
                headers: {
                    Authorization: `Bearer ${token}`,
                },
            }
        );
        const res = await response.json();
        if (!response.ok) {
            throw (res.message);
        }
        return res?.dspInvoices;
    } catch (err) {
        throw err;
    }
}

export const getDSPInvoicesStats = async (dspId, fromDate, toDate) => {
    try {
        const token = getToken();
        const url = buildUrl(DSP_INVOICES_BASE_URL + '/get/stats', {
            fromDate: fromDate ? fromDate : undefined,
            toDate: toDate ? toDate : undefined,
            dspId: dspId ? dspId : undefined
        });
        const response = await fetch(
            url,
            {
                method: "GET",
                headers: {
                    Authorization: `Bearer ${token}`,
                },
            }
        );
        const res = await response.json();
        if (!response.ok) {
            throw (res.message);
        }
        return res?.invoicesStats;
    } catch (err) {
        throw err;
    }
}

export const settleDspInvoice = async (invoice) => {
    try {
        const token = getToken();
        const response = await fetch(
            DSP_INVOICES_BASE_URL + "/settle",
            {
                method: "POST",
                body: JSON.stringify(invoice),
                headers: {
                    Authorization: `Bearer ${token} `,
                    "Content-Type": "application/json"
                },
            }
        );

        const res = await response.json();
        if (!response.ok) {
            throw (res.message || "Something went wrong while setteling the transaction");
        }
        return res;

    } catch (err) {
        throw (err || "Something went wrong while setteling the transaction");
    }
}

export const getDspSettlementInvoiceDetails = async (invoiceId) => {
    try {
        const token = getToken();
        const response = await fetch(
            DSP_INVOICES_BASE_URL + `/${invoiceId}`,
            {
                method: "GET",
                headers: {
                    Authorization: `Bearer ${token}`,
                },
            }
        );
        const res = await response.json();
        if (!response.ok) {
            throw (res.message);
        }
        return res?.settlementInvoiceDetails;
    } catch (err) {
        throw err;
    }
}