import React, { useEffect, useState } from "react";
import { getLocations, getStates, getDistricts, getTalukas } from "../../../apis/locations";
import { Button } from "../../../Components/Helper";
import {
  addDronePilot,
  getDronePilot,
  updateDronePilot,
} from "../../../apis/dronePilots";
import Popup from "../../../ReusableFunctions/Popup";
import Modal from "../../../ReusableComponents/Modal";
import { v4 as uuid } from "uuid"
import Loading from "../../../ReusableFunctions/Loader";
import InputField from "../../../ReusableComponents/InputField";
import { aadharCardValidation, addressValidation, emailValidation, licenceValidation, nameValidation, panValidation, phoneNumValidation, pincodeValidation, selectFieldValidation } from "../../../utils/inputValidations";
import Form from "../../../ReusableComponents/Form";
import SelectField from "../../../ReusableComponents/SelectField";
import ToggleField from "../../../ReusableComponents/ToggleField";
import useWindowSize from "../../../hooks/useWindowSize";

const AddDronePilot = ({ open, onClose, pilotId, onPilotAdd, indianStates }) => {
  const [locations, setLocations] = useState([]);
  const { currentHeight, currentWidth } = useWindowSize();
  const [pilot, setPilot] = useState({
    id: pilotId ? pilotId : uuid(),
    first_name: "",
    middle_name: '',
    last_name: '',
    email: "",
    phone: "",
    first_line_address: "",
    second_line_address: '',
    state: '',
    city: '',
    pincode: '',
    aadhar_number: "",
    pan_number: "",
    licence_id: "",
    is_active: true,
    location_id: null,
    dsp_id: "",
    date_created: new Date(),
  });

  const [statesLoading, setStatesLoading] = useState(false)
  const [states, setStates] = useState([])
  const [districtsLoading, setDistrictsLoading] = useState(false)
  const [districts, setDistricts] = useState([])
  const [talukasLoading, setTalukasLoading] = useState(false)
  const [talukas, setTalukas] = useState([])

  const handleAddDronePilot = (pilot) => {
    Loading.set(true)
    addDronePilot(pilot)
      .then((res) => {
        Loading.set(false)
        Popup.alert("Drone Pilot", "Pilot added successfully", "SUCCESS");
        onPilotAdd({ ...pilot, location_name: locations.find((l) => l.id === pilot?.location_id)?.name })
      })
      .catch((err) => {
        console.log(err);
        Loading.set(false)
        Popup.alert("Error", err.errorMessage, "ERROR");
      });
  };

  const handleUpdateDronePilot = (pilot) => {
    Loading.set(true)
    updateDronePilot(pilot)
      .then((res) => {
        Loading.set(false)
        Popup.alert(
          "Drone Pilot",
          "Pilot updated successfully",
          "SUCCESS"
        )
        onPilotAdd({ ...pilot, location_name: locations.find((l) => l.id === pilot?.location_id)?.name })
      })
      .catch((err) => {
        console.log(err);
        Loading.set(false)
        Popup.alert("Error", err.errorMessage, "ERROR");
      });
  };

  const getStatesFunc = async () => {
    try {
      setStatesLoading(true)
      setDistricts([])
      setTalukas([])
      const states = await getStates()
      setStates(states?.records)
      setStatesLoading(false)
    } catch (err) {
      setStatesLoading(false)
      console.log(err);
      Popup.alert("Error", err.errorMessage, "ERROR");
    }
  }

  const getDistrictsFunc = async (stateCode, stateName) => {
    try {
      setDistrictsLoading(true)
      setTalukas([])
      const districts = await getDistricts(stateCode, stateName)
      setDistricts(districts?.records)
      setDistrictsLoading(false)
    } catch (err) {
      setDistrictsLoading(false)
      console.log(err);
      Popup.alert("Error", err.errorMessage, "ERROR");
    }
  }

  const getTalukasFunc = async (districtCode, districtName) => {
    try {
      setTalukasLoading(true)
      const talukas = await getTalukas(districtCode, districtName)
      setTalukas(talukas?.records)
      setTalukasLoading(false)
    } catch (err) {
      setTalukasLoading(false)
      console.log(err);
      Popup.alert("Error", err.errorMessage, "ERROR");
    }
  }

  useEffect(() => {
    const fetchInitalData = async () => {
      Loading.set(true);
      const locations = await getLocations();
      await getStatesFunc()
      setLocations(locations);
      if (pilotId) {
        const res = await getDronePilot(pilotId);
        const pilot = res.dronePilot
        const { id, first_name, middle_name, last_name, email, phone, first_line_address, second_line_address, state, city, district, pincode, aadhar_number, pan_number, licence_id, is_active, location_id, } = pilot;
        await getDistrictsFunc(null, state)
        await getTalukasFunc(null, district)
        setPilot((prev) => ({
          ...prev,
          id: id,
          first_name: first_name,
          middle_name: middle_name,
          last_name: last_name,
          email: email,
          phone: phone,
          first_line_address: first_line_address,
          second_line_address: second_line_address,
          state: {
            label: state,
            value: state,
          },
          city: {
            label: city,
            value: city,
          },
          district: {
            label: district,
            value: district,
          },
          pincode: pincode,
          aadhar_number: aadhar_number,
          pan_number: pan_number,
          licence_id: licence_id,
          is_active: is_active,
          location_id: {
            value: location_id,
            label: locations.find(loc => loc.id === pilot.location_id)?.name
          }
        }));
      }
      Loading.set(false);
    }
    fetchInitalData().catch(err => console.log(err)).finally(() => {
      Loading.set(false);
    })
  }, [pilotId])

  return (
    <Modal
      header={pilotId ? "Edit Pilot" : "Add Pilot"}
      open={open}
      onClose={onClose}
      contentStyle={{ width: "735px", height: "auto", borderRadius: "10px" }}
      headerContainerStyle={{ padding: currentHeight < 695 ? "20px 30px" : "25px 30px" }}
    >
      <Form
        onSubmitForm={(pilot) => {
          pilot = { ...pilot, city: pilot.city.label, district: pilot.district.label, state: pilot.state.label, location_id: pilot.location_id.value };
          pilotId ? handleUpdateDronePilot(pilot) : handleAddDronePilot(pilot)
        }}
        initialValues={pilot}
      >
        {(onsubmit) => (
          <div style={{
            display: "grid",
            gridTemplateColumns: "1fr",
            gap: currentHeight < 695 ? "2px" : "5px",
            padding: currentHeight < 695 ? "15px 20px" : "20px",

          }}>
            <div style={{ display: 'flex', justifyContent: 'space-between' }}>
              <InputField
                defaultValue={pilot.first_name}
                label={"First name"}
                type={"text"}
                id={"type"}
                placeholder={"first name"}
                name={"first_name"}
                validation={nameValidation}
                containerStyle={{
                  width: "30%",
                  maxWidth: "30%",
                  minHeight: "80px",
                }}
                handleChange={(value) => {
                  setPilot((prev) => ({ ...prev, first_name: value }))
                }}
              />
              <InputField
                defaultValue={pilot.middle_name}
                label={"Middle name"}
                type={"text"}
                id={"type"}
                placeholder={"middle name"}
                name={"middle_name"}
                containerStyle={{
                  width: "30%",
                  maxWidth: "30%",
                  minHeight: "80px",
                }}
                validation={{
                  ...nameValidation,
                  required: {
                    value: false,
                    message: '',
                  },
                  validate: () => {
                    return true;
                  }
                }}
                handleChange={(value) => {
                  setPilot((prev) => ({ ...prev, middle_name: value }))
                }}
              />
              <InputField
                defaultValue={pilot.last_name}
                label={"Last Name"}
                type={"text"}
                id={"type"}
                placeholder={"last name"}
                name={"last_name"}
                containerStyle={{
                  width: "30%",
                  maxWidth: "30%",
                  minHeight: "80px",
                }}
                validation={nameValidation}
                handleChange={(value) => {
                  setPilot((prev) => ({ ...prev, last_name: value }))
                }}
              />
            </div>
            <div style={{ display: 'flex', justifyContent: 'space-between' }}>
              <InputField
                defaultValue={pilot.aadhar_number}
                label={"Aadhaar No."}
                type={"text"}
                id={"type"}
                placeholder={"Pilot Aadhaar No"}
                name={"aadhar_number"}
                containerStyle={{
                  width: "30%",
                  maxWidth: "30%",
                  minHeight: "80px",
                }}
                validation={aadharCardValidation}
                handleChange={(value) => {
                  setPilot((prev) => ({ ...prev, aadhar_number: value }))
                }}
              />
              <InputField
                defaultValue={pilot.pan_number}
                label={"PAN"}
                type={"text"}
                id={"type"}
                placeholder={"Pilot PAN"}
                name={"pan_number"}
                containerStyle={{
                  width: "30%",
                  maxWidth: "30%",
                  minHeight: "80px",
                }}
                validation={panValidation}
                handleChange={(value) => {
                  setPilot((prev) => ({ ...prev, pan_number: value }))
                }}
              />
              <InputField
                defaultValue={pilot.licence_id}
                label={"License ID"}
                type={"text"}
                id={"type"}
                placeholder={"Pilot license id"}
                name={"licence_id"}
                containerStyle={{
                  width: "30%",
                  maxWidth: "30%",
                  minHeight: "80px",
                }}
                validation={licenceValidation}
                handleChange={(value) => {
                  setPilot((prev) => ({ ...prev, licence_id: value }))
                }}
              />
            </div>

            <div style={{ display: 'flex', justifyContent: 'space-between', alignItems: "center" }}>
              <InputField
                defaultValue={pilot.phone}
                label={"Phone"}
                type={"text"}
                id={"type"}
                placeholder={"Pilot phone number"}
                name={"phone"}
                containerStyle={{
                  width: "30%",
                  maxWidth: "30%",
                  minHeight: "80px",
                }}
                validation={phoneNumValidation}
                handleChange={(value) => {
                  setPilot((prev) => ({ ...prev, phone: value }))
                }}
              />
              <InputField
                defaultValue={pilot.email}
                label={"Email"}
                type={"text"}
                id={"type"}
                placeholder={"Pilot email"}
                name={"email"}
                containerStyle={{
                  width: "30%",
                  maxWidth: "30%",
                  minHeight: "80px",
                }}
                validation={emailValidation}
                handleChange={(value) => {
                  setPilot((prev) => ({ ...prev, email: value }))
                }}
              />
              <div style={{
                width: '30%',
                paddingTop: '4px'
              }}>
                <SelectField
                  name={"location_id"}
                  label={"Location"}
                  list={locations?.map(location => ({ value: location.id, label: location.name }))}
                  styles={{ width: "215px", height: "18px", fontSize: "13px", margin: "0 0 0 -1px" }}
                  validation={selectFieldValidation}
                  containerStyle={{
                    width: "100%",
                    maxWidth: "100%",
                    minHeight: "80px"
                  }}
                  handleChange={(value) => {
                    setPilot((prev) => ({
                      ...prev,
                      location_id: value
                    }))
                  }}
                />
              </div>
            </div>

            <div style={{ display: 'flex', justifyContent: 'space-between' }}>
              <div style={{ width: "48%" }}>
                <InputField
                  defaultValue={pilot.first_line_address}
                  label={"First line address"}
                  type={"text"}
                  id={"type"}
                  placeholder={"First line address"}
                  name={"first_line_address"}
                  containerStyle={{
                    width: "100%",
                    maxWidth: "100%",
                    minHeight: "80px"
                  }}
                  validation={addressValidation}
                  handleChange={(value) => {
                    setPilot((prev) => ({ ...prev, first_line_address: value }))
                  }}
                />
              </div>
              <div style={{ width: "48%" }}>
                <InputField
                  defaultValue={pilot.second_line_address}
                  label={"Second line address"}
                  type={"text"}
                  id={"type"}
                  containerStyle={{
                    width: "100%",
                    maxWidth: "100%",
                    minHeight: "80px"
                  }}
                  placeholder={"Second line address"}
                  name={"second_line_address"}
                  validation={{
                    ...addressValidation,
                    required: {
                      value: false,
                      message: '',
                    }
                  }}
                  handleChange={(value) => {
                    setPilot((prev) => ({ ...prev, second_line_address: value }))
                  }}
                />
              </div>
            </div>
            <div style={{ display: 'flex', justifyContent: 'space-between' }}>
              <div style={{ width: "30%" }}>
                <SelectField
                  name={"state"}
                  label={"State"}
                  isLoading={statesLoading}
                  isDisabled={statesLoading}
                  list={states?.map(state => ({ value: state.state_code, label: state.state_name_english }))}
                  styles={{ width: "215px", height: "18px", fontSize: "13px", margin: "0 0 0 -1px" }}
                  validation={selectFieldValidation}
                  handleChange={(state) => {
                    if (state?.value && typeof state?.value == "number") {
                      getDistrictsFunc(state?.value)
                    }
                    setPilot((prev) => ({
                      ...prev,
                      state: state,
                      district: null,
                      city: null
                    }))
                  }}
                  containerStyle={{
                    width: "100%",
                    maxWidth: "100%",
                    minHeight: "80px"
                  }}
                  isSearchable={true}
                />
              </div>
              <div style={{ width: "30%" }}>
                <SelectField
                  name={"district"}
                  isLoading={districtsLoading}
                  isDisabled={districtsLoading}
                  label={"District"}
                  list={districts?.map(city => ({ value: city?.district_code, label: city?.district_name_english }))}
                  styles={{ width: "215px", height: "18px", fontSize: "13px", margin: "0 0 0 -1px" }}
                  validation={selectFieldValidation}
                  handleChange={(district) => {
                    if (district?.value && typeof district?.value == "number") {
                      getTalukasFunc(district?.value)
                    }
                    setPilot((prev) => ({
                      ...prev,
                      district: district,
                      city: null
                    }))
                  }}
                  isSearchable={true}
                  containerStyle={{
                    width: "100%",
                    maxWidth: "100%",
                    minHeight: "80px"
                  }}
                />
              </div>
              <div style={{ width: "30%" }}>
                <SelectField
                  name={"city"}
                  isLoading={talukasLoading}
                  isDisabled={talukasLoading}
                  label={"City"}
                  list={talukas?.map(city => ({ value: city?.subdistrict_code, label: city?.subdistrict_name_english }))}
                  styles={{ width: "215px", height: "18px", fontSize: "13px", margin: "0 0 0 -1px" }}
                  validation={selectFieldValidation}
                  isSearchable={true}
                  containerStyle={{
                    width: "100%",
                    maxWidth: "100%",
                    minHeight: "80px"
                  }}
                  handleChange={(taluka) => {
                    setPilot((prev) => ({
                      ...prev,
                      city: taluka
                    }))
                  }}
                />
              </div>
            </div>
            <div style={{ display: 'flex', justifyContent: 'space-between' }}>
              <div style={{ width: "30%" }}>
                <InputField
                  defaultValue={pilot.pincode}
                  label={"Pincode"}
                  type={"text"}
                  id={"type"}
                  placeholder={"pincode"}
                  name={"pincode"}
                  containerStyle={{
                    width: "100%",
                    maxWidth: "100%",
                    minHeight: "80px"
                  }}
                  validation={pincodeValidation}
                  handleChange={(value) => {
                    setPilot((prev) => ({ ...prev, pincode: value }))
                  }}
                />
              </div>
            </div>
            <div>
              <ToggleField
                label={"Status"}
                name={"is_active"}
                onText={"Active"}
                offText={"Inactive"}
                defaultValue={pilot.is_active}
                handleChange={(e) => {
                  setPilot((prev) => ({ ...prev, is_active: e.target.checked }))
                }}
              />
            </div>
            <div style={{
              display: "flex",
              flexDirection: "row-reverse",
              gap: "20px",
              padding: currentHeight < 695 ? "10px" : "20px",
              height: "70px"
            }}>
              <Button
                text={pilotId ? "Update" : "Add"}
                onClick={onsubmit}
                className={"btn-large"}
              />
              <Button
                className={"btn-large lg-cancel-btn"}
                text={"Cancel"}
                onClick={onClose}
                isBtnNotActive={true}
              />
            </div>
          </div>
        )}
      </Form>
    </Modal >
  );
};

export default AddDronePilot;
