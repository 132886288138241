import React, { useEffect } from "react";
import L from "leaflet";
let baseLayer
const Map = ({
  mapInitialized,
  handleMapClick,
  maxZoom,
  initCenter,
  style,
  setBaseLayerToState
}) => {
  useEffect(() => {
    // Create the map instance
    const map = L.map("map").setView([20.008637, 73.755229], 16);
    map.setMaxZoom(22);
    mapInitialized(map);
    // Add the tile layer from OpenStreetMap
    baseLayer = L.tileLayer("https://{s}.tile.openstreetmap.org/{z}/{x}/{y}.png", {
      attribution:
        '&copy; <a href="https://www.openstreetmap.org/copyright">OpenStreetMap</a> contributors',
      maxZoom: maxZoom,
      center: initCenter,
      zoom: 18,
      tileSize: 256,
    }).addTo(map);

    if (handleMapClick) map.on("click", handleMapClick);
    if (setBaseLayerToState) setBaseLayerToState(baseLayer);
    // Clean up the map instance on unmount
    return () => map.remove();
  }, []);
  return (
    <div id="map" style={{ height: "100%", width: "100%", ...style }}></div>
  );
};

export default Map;
