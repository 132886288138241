import { getDSPId } from "../ReusableFunctions/reusableFunctions";
import { SERVER_URLS } from "../appConstants";
import { getToken } from "./authentication";

export const getLocations = ({ is_active, service_type } = {}) => {
  console.log(service_type)
  return new Promise(async (resolve, reject) => {
    try {
      const token = getToken();
      const response = await fetch(
        SERVER_URLS.BACKEND_SERVER + `/dsp/locations?temp=true${is_active ? `&is_active=true` : ""}${service_type ? `&service_type=${service_type}` : ""}`,
        {
          method: "GET",
          headers: {
            Authorization: `Bearer ${token}`,
          },
        }
      );
      const data = await response.json();
      if (response.ok) {
        resolve(data.locations);
      } else {
        reject(
          data?.message || "Something went wrong while getting locations."
        );
      }
    } catch (error) {
      reject("Something went wrong while getting locations.");
    }
  });
};

export const getCitiesByState = (state) => {
  return new Promise(async (resolve, reject) => {
    try {
      const token = getToken();
      const response = await fetch(
        SERVER_URLS.BACKEND_SERVER + `/dsp/locations/getCities?state=${encodeURIComponent(state)}`,
        {
          method: "GET",
          headers: {
            Authorization: `Bearer ${token}`,
          },
        }
      );
      const data = await response.json();
      resolve(data.results);
    } catch (error) {
      reject("Something went wrong while getting cities.");
    }
  });
};

export const addLocation = (location) => {
  return new Promise(async (resolve, reject) => {
    try {
      const token = getToken();
      const response = await fetch(
        SERVER_URLS.BACKEND_SERVER + "/dsp/locations",
        {
          method: "POST",
          body: JSON.stringify({ location }),
          headers: {
            "Content-Type": "application/json",
            Authorization: `Bearer ${token}`,
          },
        }
      );
      const data = await response.json();
      if (response.status === 200) {
        resolve(data);
      }
      else if (response.status === 409) {
        reject(data?.message || "Location already added.")
      }
      else {
        reject(data?.message || "Something went wrong while adding location.");
      }
    } catch (error) {
      reject("Something went wrong while adding location.");
    }
  });
};

export const updateLocation = (location) => {
  return new Promise(async (resolve, reject) => {
    try {
      const token = getToken();
      const response = await fetch(
        SERVER_URLS.BACKEND_SERVER + "/dsp/locations",
        {
          method: "PUT",
          body: JSON.stringify({ location }),
          headers: {
            "Content-Type": "application/json",
            Authorization: `Bearer ${token}`,
          },
        }
      );
      const data = await response.json();
      if (response.status === 200) {
        resolve(data);
      }
      else if (response.status === 409) {
        reject(
          data?.message || "The location your are trying to update is already added to your locations."
        );
      }
      else {
        reject(
          data?.message || "Something went wrong while updating location."
        );
      }
    } catch (error) {
      reject("Something went wrong while updating location.");
    }
  });
};

export const getLocation = (id) => {
  return new Promise(async (resolve, reject) => {
    try {
      const token = getToken();
      const response = await fetch(
        SERVER_URLS.BACKEND_SERVER + "/dsp/locations/" + id,
        {
          method: "GET",
          headers: {
            Authorization: `Bearer ${token}`,
          },
        }
      );
      const data = await response.json();
      if (response.ok) {
        resolve(data);
      } else {
        reject(
          data?.message || "Something went wrong while getting member location."
        );
      }
    } catch (error) {
      reject("Something went wrong while getting member location.");
    }
  });
};

export const getStates = async () => {
  return new Promise(async (resolve, reject) => {
    try {
      const token = getToken();
      const response = await fetch(
        SERVER_URLS.BACKEND_SERVER + "/dsp/locations/states/get",
        {
          method: "GET",
          headers: {
            Authorization: `Bearer ${token}`,
          },
        }
      );
      const data = await response.json();
      if (response.ok) {
        resolve(data);
      } else {
        reject(
          data?.message || "Something went wrong while getting states."
        );
      }
    } catch (error) {
      reject("Something went wrong while getting states.");
    }
  });
}

export const getDistricts = async (stateCode, stateName) => {
  return new Promise(async (resolve, reject) => {
    try {
      const token = getToken();
      const queryParams = new URLSearchParams();
      if (stateCode) queryParams.append("stateCode", stateCode);
      if (stateName) queryParams.append("stateName", stateName);

      const response = await fetch(
        `${SERVER_URLS.BACKEND_SERVER}/dsp/locations/districts/get?${queryParams.toString()}`,
        {
          method: "GET",
          headers: {
            Authorization: `Bearer ${token}`,
          },
        }
      );

      const data = await response.json();

      if (response.ok) {
        resolve(data);
      } else {
        reject(
          data?.message || "Something went wrong while getting districts."
        );
      }
    } catch (error) {
      console.error("Error fetching districts:", error);
      reject("Something went wrong while getting districts.");
    }
  });
};


export const getTalukas = async (districtCode, districtName) => {
  return new Promise(async (resolve, reject) => {
    try {
      const token = getToken();
      const queryParams = new URLSearchParams();
      if (districtCode) queryParams.append("districtCode", districtCode);
      if (districtName) queryParams.append("districtName", districtName);

      const response = await fetch(
        `${SERVER_URLS.BACKEND_SERVER}/dsp/locations/talukas/get?${queryParams.toString()}`,
        {
          method: "GET",
          headers: {
            Authorization: `Bearer ${token}`,
          },
        }
      );

      const data = await response.json();

      if (response.ok) {
        resolve(data);
      } else {
        reject(
          data?.message || "Something went wrong while getting talukas."
        );
      }
    } catch (error) {
      console.error("Error fetching talukas:", error);
      reject("Something went wrong while getting talukas.");
    }
  });
};

