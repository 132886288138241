import React, { useEffect, useState } from 'react'
import { useLocation } from 'react-router-dom'
import search from "../../../assets/search.svg"
import TableView from '../../../ReusableComponents/TableView/TableView'
import AdminFilterRequest from './AdminFilterRequest'
import Spraying from "../../../assets/spraying.svg";
import Survey from "../../../assets/survey.svg";
import Training from "../../../assets/training.svg";
import { SERVICE_TYPES } from '../../../appConstants'
import Tippy from '@tippyjs/react'

const getCurrentScreenSize = () => {
    const width = window.innerWidth;
    if (width >= 1860) {
        return "x-large"
    } else if (width < 1860 && width > 1620) {
        return "large";
    } else if (width <= 1620 && width > 1400) {
        return "medium";
    } else if (width > 1200 && width <= 1400) {
        return "small"
    }
}

const AdminRequestTable = ({ pageNumber, requests, onFilterChange, loading, setLoading, dateTime, currentPage, totalCount, onCurrentPageChange, pageSize, isFromUserDetails, onSearch, tabelContainerHeight, hidePagination, allCount, onClickRow }) => {
    const { state } = useLocation();
    const [openFilter, setOpenFilter] = useState(false)
    const [currentWidth, setcurrentWidth] = useState(getCurrentScreenSize());
    const [localRequest, setLocalRequests] = useState(requests)
    const [screenWidth, setScreenWidth] = useState(1366)

    useEffect(() => {
        setLocalRequests(requests)
    }, [requests])

    useEffect(() => {
        const screenWidth = window.innerWidth;
        setScreenWidth(screenWidth)
        const handleResize = () => {
            setcurrentWidth(getCurrentScreenSize())
        }
        window.addEventListener("resize", handleResize);
        return () => {
            window.addEventListener("resize", handleResize);
        }
    }, [])

    return (
        <>
            <div style={{ fontWeight: 600, display: "flex", alignItems: "center", width: "100%", justifyContent: "space-between", gap: "20px", padding: "0px 0px 15px" }}>
                <div className='request-table-header-title' >
                    <div className='request-table-header-title-div' style={{ fontSize: "1.5rem", fontWeight: 700 }}>
                        {`Requests (${localRequest[0]?.total_count || 0})`}
                    </div>
                    {onSearch &&
                        <div style={{ position: "relative", height: "40px", width: "400px" }}>
                            <img src={search} alt='search icon' style={{ position: 'absolute', left: "20px", top: "10px" }} />
                            <input onChange={(e) => onSearch(e.target.value)}
                                placeholder='Search request by request no...'
                                style={{ width: "100%", height: '100%', borderRadius: "4px", border: "1px solid #BFC8D9", padding: "10px 10px 10px 60px", fontSize: "12px" }} />
                        </div>
                    }
                </div>
                <AdminFilterRequest
                    user={state?.userId}
                    open={openFilter}
                    dateTime={dateTime}
                    onClose={() => setOpenFilter(false)}
                    callback={(filters, dateTime) => {
                        onFilterChange(filters, dateTime)
                    }}
                    setOpenFilter={setOpenFilter}
                    screenWidth={currentWidth}
                    loading={loading}
                    setLoading={setLoading}
                    isFromUserDetails={isFromUserDetails}
                />
            </div>
            <TableView
                loadingHeight={"639px"}
                isLoading={loading}
                onStateChange={async (changedState) => {
                    const { pageNumber } = changedState;
                    onCurrentPageChange(pageNumber);
                }}
                onClickRow={(request) => {
                    onClickRow(request)
                }}
                columns={[
                    { Header: "Type", accessor: "serviceType", width: "5%" },
                    { Header: "Request Number", accessor: "req_number", width: "10%" },
                    { Header: "Status", accessor: "status", width: "10%" },
                    // { Header: "User Name", accessor: "user_name" },
                    // { Header: "Phone number", accessor: "user_phone" },
                    { Header: "DSP", accessor: "dsp_name", width: "27.5%" },
                    { Header: "Location", accessor: "location_name", width: "27.5%" },
                    { Header: "Total Amount", accessor: "total_amount", width: "10%" },
                    { Header: "Date Created", accessor: "date_created", isDate: true, format: "dd/mm/yyyy", width: "10%" },
                ]}
                count={totalCount}
                pageSize={pageSize}
                pageNumber={pageNumber || undefined}
                data={localRequest?.map((req) => ({ ...req, status: req.status, serviceType: <Tippy content={req?.type?.split(' ')[0] === SERVICE_TYPES[0] ? "Spraying Service" : "Survey Service"}><img style={{ width: "30px" }} src={req?.type?.split(' ')[0] === SERVICE_TYPES[0] ? Spraying : Survey} /></Tippy>, dsp_name: req?.dsp?.name }))}
                tableContainerHeight={tabelContainerHeight || '681px'}
                hidePagination={hidePagination}
            />
        </>

    )
}

export default AdminRequestTable