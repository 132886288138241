import React, { useEffect, useState } from 'react'
import MainContentWrapper from '../../Components/MainContentWrapper'
import Popup from '../../ReusableFunctions/Popup'
import Loading from '../../ReusableFunctions/Loader'
import { getAllRequests, getAllRequestsCount } from '../../apis/adminAPIs/requests'
import ViewRequest from './SubComponents/ViewRequest'
import AdminRequestTable from './SubComponents/AdminRequestTable'
import { getStatusWiseRequestCount } from '../../apis/adminAPIs/overview'
import StatusCountContainer from './SubComponents/StatusCountContainer'
import { useNavigate } from 'react-router'
import { PDRL_SUCCESS_ENGI_ROUTES } from '../../routes'

const Requests = () => {
    const requestPerPage = 13
    const [loading, setLoading] = useState()
    const [requests, setRequests] = useState([])
    const [selectedRequest, setSelectedRequest] = useState()
    const [pageNumber, setpageNumber] = useState(undefined);
    const [filetrs, setFilters] = useState({ services: [], statuses: [], locations: [], isDspActive: true })
    const [filterDateTime, setFilterDateTime] = useState({ fromDate: (new Date()).setHours(0, 0, 0, 0), toDate: new Date() })
    const [searchString, setSearchString] = useState('')
    const [allRequestCount, setAllRequestCount] = useState(0)
    const [statusWiseRequestsCount, setStatusWiseRequestsCount] = useState([])
    const navigate = useNavigate();

    const getAllRequestsFunc = async (pageSize, pageNumber, filters, searchQuery, fromDate, toDate) => {
        try {
            setLoading(true)
            const data = await getAllRequests({ filters, pageSize, pageNumber, searchQuery, fromDate, toDate })
            setRequests(data?.requests)
            if (!pageSize || !pageNumber || !filters || !fromDate || !toDate) {
                setAllRequestCount(data?.requests[0]?.total_count)
            }
            Loading.set(false)
            setLoading(false)
        } catch (err) {
            setLoading(false)
            Loading.set(false)
            Popup.alert("Error", err, "ERROR")
        }
    }

    const getAllRequestCountFunc = async () => {
        try {
            setLoading(true)
            const count = await getAllRequestsCount({ is_dsp_active: true })
            setAllRequestCount(count?.total)
            setLoading(false)
        } catch (err) {
            setLoading(false)
            Loading.set(false)
            Popup.alert("Error", err, "ERROR")
        }
    }

    const getStatusWiseRequestCountFunc = async (filters, searchQuery, fromDate, toDate) => {
        try {
            setLoading(true)
            const statusRequestCount = await getStatusWiseRequestCount(filters, searchQuery, fromDate, toDate)
            setStatusWiseRequestsCount(statusRequestCount?.requessts)
            setLoading(false)
        } catch (error) {
            setLoading(false)
            Loading.set(false)
            Popup.alert("Error", error, "ERROR")
        }
    }

    const getRequest = async () => {
        Loading.set(true);
        setLoading(true);
        let today = new Date();
        today.setHours(0, 0, 0, 0);
        let fromDate = today.toISOString();
        const toDate = (new Date()).toISOString()
        await getAllRequestsFunc(requestPerPage, 1, filetrs, '', fromDate, toDate)
        Loading.set(false);
        setLoading(false);
    }

    useEffect(() => {
        getAllRequestCountFunc()
        let today = new Date();
        today.setHours(0, 0, 0, 0);
        let fromDate = today.toISOString();
        const toDate = (new Date()).toISOString()

        getStatusWiseRequestCountFunc(filetrs, "", fromDate, toDate)
        getRequest()
    }, [])
    console.log("requests", requests);
    return (
        <MainContentWrapper
            containerStyle={{
                padding: "36px 53px"
            }}
        >
            <StatusCountContainer
                requestsStatuses={statusWiseRequestsCount}
            />
            <AdminRequestTable
                tabelContainerHeight={"690px"}
                pageNumber={pageNumber}
                requests={requests}
                dateTime={filterDateTime}
                onFilterChange={(filters, dateTime) => {
                    setFilters(filters)
                    setFilterDateTime(dateTime)
                    setpageNumber(1)
                    getAllRequestsFunc(requestPerPage, 1, filters, searchString, dateTime?.fromDate ? new Date(dateTime.fromDate).toISOString() : null, dateTime?.toDate ? new Date(dateTime.toDate).toISOString() : null)
                    getStatusWiseRequestCountFunc(filters, searchString, dateTime?.fromDate ? new Date(dateTime.fromDate).toISOString() : null, dateTime?.toDate ? new Date(dateTime.toDate).toISOString() : null)
                }}
                loading={loading}
                setLoading={setLoading}
                onSearch={(searchQuery) => {
                    setSearchString(searchQuery)
                    getAllRequestsFunc(requestPerPage,
                        1,
                        filetrs,
                        searchQuery,
                        filterDateTime && filterDateTime.fromDate ? new Date(filterDateTime.fromDate).toISOString() : null,
                        filterDateTime && filterDateTime.toDate ? new Date(filterDateTime.toDate).toISOString() : null
                    )
                    getStatusWiseRequestCountFunc(filetrs, searchQuery, filterDateTime && filterDateTime.fromDate ? new Date(filterDateTime.fromDate).toISOString() : null, (filterDateTime && filterDateTime.toDate) ? new Date(filterDateTime.toDate).toISOString() : null)
                }}
                onCurrentPageChange={(pageNumber) => {
                    setpageNumber(pageNumber)
                    getAllRequestsFunc(requestPerPage,
                        pageNumber,
                        filetrs,
                        searchString,
                        filterDateTime && filterDateTime.fromDate ? new Date(filterDateTime.fromDate).toISOString() : undefined,
                        filterDateTime && filterDateTime.toDate ? new Date(filterDateTime.toDate).toISOString() : undefined)
                }}
                totalCount={requests[0]?.total_count || 0}
                pageSize={requestPerPage}
                allCount={allRequestCount}
                onClickRow={(request) => {
                    request = { ...request, serviceType: request?.serviceType?.props?.content }
                    setSelectedRequest(request)
                    navigate(`${PDRL_SUCCESS_ENGI_ROUTES.REQUESTDETAILS}`, { state: { request: request } })
                }}
            />
        </MainContentWrapper>
    )
}

export default Requests