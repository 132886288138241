import React, { useState } from 'react';
import DateTimePicker from '../../../ReusableComponents/DateTimePicker';
import Modal from '../../../ReusableComponents/Modal';
import { GLOBAL_STYLES, PAYMENT_STATUSES } from '../../../appConstants';
import { Button } from '../../../Components/Helper';

const UserInvoicesFilter = ({ filter, onFilterChange, open, onClose }) => {
    const [invoicefilter, setInvoicesFilter] = useState(filter);

    const handleFilterChange = (updatedFilter) => {
        setInvoicesFilter(updatedFilter);
    };

    return (
        <Modal
            header={"Filter"}
            contentStyle={{ width: "730px", height: "366px" }}
            headingStyle={{ fontSize: "18px", fontWeight: 600 }}
            headerContainerStyle={{ padding: "20px" }}
            open={open}
            onClose={onClose}
        >
            <div style={{ padding: "20px 30px", display: "flex", flexDirection: "column", width: "100%", gap: "20px" }}>
                {/* Date Filter Section */}
                <div style={{ display: "flex", justifyContent: "space-between", alignItems: "center", width: "100%", gap: "10%" }}>
                    <DateTimePicker
                        title="From"
                        hideNxt={new Date()}
                        selectedDateTime={invoicefilter.fromDate}
                        onChange={(date) => {
                            handleFilterChange({
                                ...invoicefilter,
                                fromDate: new Date(date).toISOString()
                            });
                        }}
                    />
                    <DateTimePicker
                        title="To"
                        hidePrev={invoicefilter.fromDate}
                        selectedDateTime={invoicefilter.toDate}
                        onChange={(date) => {
                            if (new Date(invoicefilter.fromDate) < new Date(date)) {
                                handleFilterChange({
                                    ...invoicefilter,
                                    toDate: new Date(date).toISOString()
                                });
                            } else {
                                handleFilterChange({
                                    ...invoicefilter,
                                    toDate: invoicefilter.toDate ? new Date(invoicefilter.toDate) : new Date()
                                })
                            }
                        }}
                        isDisable={!(invoicefilter.fromDate)}
                    />
                </div>

                {/* Active/Inactive/All Filter Section */}
                <div style={{ marginTop: "20px" }}>
                    <h4 style={{ marginBottom: '10px', fontWeight: 600 }}>Payment Mode</h4>
                    <div style={{ display: "flex", gap: "10px" }}>
                        <button
                            style={{
                                padding: "10px 20px",
                                borderRadius: "5px",
                                border: "1px solid",
                                borderColor: invoicefilter.paymentMode === undefined ? "#007bff" : "#ccc",
                                backgroundColor: invoicefilter.paymentMode === undefined ? "#007bff" : "#fff",
                                color: invoicefilter.paymentMode === undefined ? "#fff" : "#000",
                                cursor: "pointer",
                                transition: "all 0.3s ease",
                                fontWeight: invoicefilter.paymentMode === undefined ? "600" : "400"
                            }}
                            onClick={() => handleFilterChange({ ...invoicefilter, paymentMode: undefined })}
                        >
                            All
                        </button>
                        <button
                            style={{
                                padding: "10px 20px",
                                borderRadius: "5px",
                                border: "1px solid",
                                borderColor: invoicefilter.paymentMode === "UPI" ? "#28a745" : "#ccc",
                                backgroundColor: invoicefilter.paymentMode === "UPI" ? "#28a745" : "#fff",
                                color: invoicefilter.paymentMode === "UPI" ? "#fff" : "#000",
                                cursor: "pointer",
                                transition: "all 0.3s ease",
                                fontWeight: invoicefilter.paymentMode === "UPI" ? "600" : "400"
                            }}
                            onClick={() => handleFilterChange({ ...invoicefilter, statuses: [PAYMENT_STATUSES.PAID], paymentMode: "UPI" })}
                        >
                            UPI
                        </button>
                        <button
                            style={{
                                padding: "10px 20px",
                                borderRadius: "5px",
                                border: "1px solid",
                                borderColor: invoicefilter.paymentMode === "Offline" ? "#dc3545" : "#ccc",
                                backgroundColor: invoicefilter.paymentMode === "Offline" ? "#dc3545" : "#fff",
                                color: invoicefilter.paymentMode === "Offline" ? "#fff" : "#000",
                                cursor: "pointer",
                                transition: "all 0.3s ease",
                                fontWeight: invoicefilter.paymentMode === "Offline" ? "600" : "400"
                            }}
                            onClick={() => handleFilterChange({ ...invoicefilter, statuses: [PAYMENT_STATUSES.PAID], paymentMode: "Offline" })}
                        >
                            Cash
                        </button>
                    </div>
                </div>
            </div>
            <div style={{ display: "flex", justifyContent: "space-between", padding: "20px 30px", borderTop: '0.5px solid #BFC8D9' }}>
                <div
                    onClick={async () => {
                        setInvoicesFilter({
                            ...invoicefilter,
                            fromDate: null,
                            toDate: null,
                        })
                    }} style={{ width: "100px", color: GLOBAL_STYLES.BG_ACTIVE, fontWeight: 600, fontSize: "14px", cursor: 'pointer', display: "flex", alignItems: "center", justifyContent: "start" }}>
                    Clear all
                </div>
                <Button
                    text={"Apply"}
                    style={{ width: "130px", height: "38px", fontSize: "15px", marginRight: "10px" }}
                    onClick={() => {
                        onFilterChange(invoicefilter)
                    }}
                />
            </div>
        </Modal>
    );
};

export default UserInvoicesFilter;