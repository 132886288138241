import { GLOBAL_STYLES, LIMITS, STATUS } from "../appConstants";
export const getDSPId = () => {
  return localStorage.getItem("dsp_id");
}
export function buildUrl(baseUrl, params) {
  const queryParams = Object.keys(params)
    .filter(key => params[key] !== undefined && params[key] !== null) // Filter out null/undefined values
    .map(key => `${encodeURIComponent(key)}=${encodeURIComponent(params[key])}`) // Encode key-value pairs
    .join('&');

  return queryParams ? `${baseUrl}?${queryParams}` : baseUrl;
}
export function getFormatedDate(date, inString) {
  // Create a new Date object
  var currentDate = new Date(date);

  if (inString) {
    // Format the date in words
    var options = { year: 'numeric', month: 'long', day: 'numeric' };
    var formattedDateTime = currentDate.toLocaleDateString('en-US', options);
    return formattedDateTime;
  } else {
    // Format the date and time
    var year = currentDate.getFullYear();
    var month = currentDate.getMonth() + 1; // Months are zero-based, so add 1
    var day = currentDate.getDate();
    var hours = currentDate.getHours();
    var minutes = currentDate.getMinutes();
    var seconds = currentDate.getSeconds();

    var formattedDateTime =
      addLeadingZero(day) + "/" + addLeadingZero(month) + "/" + year
    return formattedDateTime;
  }
}

export function getFormatedDateAndTime(date, inString) {
  // Create a new Date object
  var currentDate = new Date(date);

  if (inString) {
    // Format the date in words
    var options = { year: 'numeric', month: 'long', day: 'numeric' };
    var formattedDateTime = currentDate.toLocaleDateString('en-US', options);
    return formattedDateTime;
  } else {
    // Format the date and time
    var year = currentDate.getFullYear();
    var month = currentDate.getMonth() + 1; // Months are zero-based, so add 1
    var day = currentDate.getDate();
    var hours = currentDate.getHours();
    var minutes = currentDate.getMinutes();
    var seconds = currentDate.getSeconds();

    const ampm = hours >= 12 ? 'PM' : 'AM';

    // Convert hours to 12-hour format
    hours = hours % 12;
    hours = hours ? hours : 12; // If hours is 0, set it to 12

    // Add leading zero to minutes if necessary
    minutes = minutes < 10 ? '0' + minutes : minutes;

    var formattedDateTime =
      addLeadingZero(day) + "/" + addLeadingZero(month) + "/" + year + ` ${hours}:${minutes || '00'} ${ampm}`
    return formattedDateTime;
  }
}

export const combineDateTime = (dateWithTime, dateWithDate) => {
  // Extract time from dateWithTime
  var hours = dateWithTime.getHours();
  var minutes = dateWithTime.getMinutes();
  var seconds = dateWithTime.getSeconds();

  // Clone dateWithDate to avoid modifying the original object
  var combinedDate = new Date(dateWithDate);

  // Set the time from dateWithTime to combinedDate
  combinedDate.setHours(hours);
  combinedDate.setMinutes(minutes);
  combinedDate.setSeconds(seconds);

  return combinedDate;
}

export const getFormattedTimeFromDate = (date) => {
  date = new Date(date)
  let hours = date.getHours();
  let minutes = date.getMinutes();
  const ampm = hours >= 12 ? 'PM' : 'AM';

  // Convert hours to 12-hour format
  hours = hours % 12;
  hours = hours ? hours : 12; // If hours is 0, set it to 12

  // Add leading zero to minutes if necessary
  minutes = minutes < 10 ? '0' + minutes : minutes;

  return `${hours}:${minutes || '00'} ${ampm}`;
}

// Helper function to add a leading zero if the value is less than 10
export function addLeadingZero(value) {
  return value < 10 ? "0" + value : value;
}
export const reduceLongName = (name, limit) => {
  return name?.length > limit ? name?.substring(0, limit) + "..." : name;
};

export const isEmailValid = (email) => {
  return String(email)
    .toLowerCase()
    .match(
      /^[a-zA-Z0-9._+-]+@[a-zA-Z0-9.-]+\.[a-zA-Z]{2,}$/
    ) && email.length > 0 && email.length <= LIMITS.EMAIL;
};

export const validatePassword = (password) => {
  let uppercaseRegex = /[A-Z]/g;
  if (password.match(uppercaseRegex)) {
  } else {
    return "Password should contain 1 uppercase letter.";
  }

  // checking lowercase letters
  let lowercaseRegex = /[a-z]/g;
  if (password.match(lowercaseRegex)) {
  } else {
    return "Password should contain 1 lowercase letter.";
  }

  // checking the number
  let numbersRegex = /[0-9]/g;
  if (password.match(numbersRegex)) {
  } else {
    return "Password should contain 1 number letter.";
  }

  // Checking length of the password
  if (password.length >= 8) {
  } else {
    return "Password should be 8 letter long.";
  }
  return "";
};
export const validatePhoneNumber = (phoneNo) => {
  return /^\d+$/.test(phoneNo) && phoneNo && phoneNo.length === 10
};

export const validateAdhaarCardNo = (adn) => {
  const aadharPattern = /^\d{4}\d{4}\d{4}$/;
  if (adn.match(aadharPattern)) { }
  else {
    return "Invalid aadhar card number."
  }
  if (adn.length < LIMITS.AADHAR_LENGTH) {
    return "Aadhar number is too short."
  }
  if (adn.length > LIMITS.AADHAR_LENGTH) {
    return "Aadhar number is too long."
  }
  return "";
};

export const validatePanNumber = (panNumber) => {
  const panPattern = /^[A-Z]{5}[0-9]{4}[A-Z]$/;
  if (!panNumber.match(panPattern)) {
    return "Pan number is invalid."
  }
}

export const isEmptyString = (inputStr) => {
  if (/^\s*$/.test(inputStr)) {
    return true;
  } else
    return false;
}

export const isSpecialCharExists = (inputStr) => {
  if (/[^a-zA-Z0-9\s]/.test(inputStr)) {
    return true;
  }
  return false;
}

export const getStatusColor = (status, text) => {
  switch (status) {
    case STATUS.CANCELLED: return text ? GLOBAL_STYLES.STATUS.TEXT.CANCELLED : GLOBAL_STYLES.STATUS.BACKGROUND.CANCELLED
    case STATUS.ACCEPTED: return text ? GLOBAL_STYLES.STATUS.TEXT.ACCEPTED : GLOBAL_STYLES.STATUS.BACKGROUND.ACCEPTED
    case STATUS.COMPLETED: return text ? GLOBAL_STYLES.STATUS.TEXT.COMPLETED : GLOBAL_STYLES.STATUS.BACKGROUND.COMPLETED
    case STATUS.INPROGRESS: return text ? GLOBAL_STYLES.STATUS.TEXT.INPROGRESS : GLOBAL_STYLES.STATUS.BACKGROUND.INPROGRESS
    case STATUS.PLACED: return text ? GLOBAL_STYLES.STATUS.TEXT.PLACED : GLOBAL_STYLES.STATUS.BACKGROUND.PLACED
    case STATUS.PAID: return text ? GLOBAL_STYLES.STATUS.TEXT.PAID : GLOBAL_STYLES.STATUS.BACKGROUND.PAID
    case STATUS.OUT_FOR_SERVICE: return text ? GLOBAL_STYLES.STATUS.TEXT.OUT_FOR_SERVICE : GLOBAL_STYLES.STATUS.BACKGROUND.OUT_FOR_SERVICE
    case STATUS.RESCHEDULED: return text ? GLOBAL_STYLES.STATUS.TEXT.RESCHEDULED : GLOBAL_STYLES.STATUS.BACKGROUND.RESCHEDULED
    case STATUS.HOLD: return text ? GLOBAL_STYLES.STATUS.TEXT.HOLD : GLOBAL_STYLES.STATUS.BACKGROUND.HOLD
  }
}

function getDayWithSuffix(day) {
  if (day >= 11 && day <= 13) {
    return `${day}th`;
  }
  switch (day % 10) {
    case 1:
      return `${day}st`;
    case 2:
      return `${day}nd`;
    case 3:
      return `${day}rd`;
    default:
      return `${day}th`;
  }
}

export function getWeekNumber(d) {
  d = new Date(+d);
  d.setHours(0, 0, 0, 0);
  d.setDate(d.getDate() + 4 - (d.getDay() || 7));
  var yearStart = new Date(d.getFullYear(), 0, 1);
  var weekNo = Math.ceil((((d - yearStart) / 86400000) + 1) / 7)
  return [d.getFullYear(), weekNo];
}

// get total weeks in praticular year
export function weeksInYear(year) {
  let leapYear = false;
  if ((year % 4 === 0 && year % 100 !== 0) || year % 400 === 0) {
    leapYear = true;
  }
  const daysInYear = leapYear ? 366 : 365;
  return Math.ceil(daysInYear / 7);
}

export const getStartAndEndDates = async (type, dateNumber, year = new Date().getFullYear()) => {
  return new Promise((resolve, reject) => {

    if (type === "week") {
      const januaryFirst = new Date(year, 0, 1);
      const daysOffset = (dateNumber - 1) * 7;
      const firstDayOfWeek = new Date(januaryFirst.getTime() + daysOffset * 24 * 60 * 60 * 1000);
      const startOfWeek = new Date(firstDayOfWeek);
      startOfWeek.setDate(firstDayOfWeek.getDate() - firstDayOfWeek.getDay() + 1);
      const endOfWeek = new Date(startOfWeek);
      endOfWeek.setDate(startOfWeek.getDate() + 6);
      return resolve({ startDate: startOfWeek.toISOString().split('T')[0], endDate: endOfWeek.toISOString().split('T')[0] });
    } else if (type === "month") {
      const monthNumber = isNaN(dateNumber) ? new Date(`${dateNumber
        } 1, ${year}`).getMonth() : parseInt(dateNumber
          , 10);
      const startOfMonth = new Date(year, monthNumber, 2);
      const endOfMonth = new Date(year, monthNumber + 1, 1);
      return resolve({ startDate: startOfMonth.toISOString().split('T')[0], endDate: endOfMonth.toISOString().split('T')[0] });
    } else return resolve()
  })
}


export function addCommasToAmount(amount) {
  let amountString = parseInt(amount).toLocaleString('en-IN')
  return amountString;
}

export function formatDateTime(inputDateTime) {
  const inputDate = new Date(inputDateTime);

  // Format date
  const options = { day: 'numeric', month: 'short', year: 'numeric' };
  const formattedDate = `${inputDate.getUTCDate()} ${inputDate.toLocaleDateString('en-US', { month: 'short' })} ${inputDate.getUTCFullYear()}`;

  // Format time
  var hours = inputDate.getHours();
  const minutes = inputDate.getMinutes();
  const ampm = hours >= 12 ? 'PM' : 'AM';
  if (hours > 12) {
    hours = hours % 12;
  }
  const formattedTime = `${hours.toString().padStart(2, '0')}:${minutes.toString().padStart(2, '0')} ${ampm}`;

  // Combine date and time
  const formattedDateTime = `${formattedDate} ${formattedTime}`;

  return formattedDateTime;
}

// YYYY/DD/MM return date format
export const getYYYMMDDFormatDateFromTime = (dateTime) => {
  dateTime = new Date(dateTime)
  return `${dateTime.getFullYear()}/${String(dateTime.getMonth() + 1).padStart(2, '0')}/${String(dateTime.getDate()).padStart(2, '0')}`;
}

// accept the input in number and decimal point till 100
export const isValidGst = (value) => {
  const regex = /^(100(\.0{1,2})?|0(\.[0-9]{1,2})?|[1-9][0-9]?(\.[0-9]{1,2})?)$/;
  return regex.test(value);
};

//  convert acre to sq/km
export const acresToSquareKilometers = (acres) => {
  const conversionFactor = 0.00404686;
  return acres * conversionFactor;
}
//  convert sq/km to acre 
export const squareKilometersToAcres = (sqKm) => { return (Number((Number(sqKm) * 247.105381).toFixed(3))) }

export function capitalizeFirstLetter(sentence) {
  if (!sentence) return '';

  return sentence
    .split(' ')
    .map(word =>
      word.split('')
        .map((char, index) =>
          index === 0 && char >= 'a' && char <= 'z'
            ? char.toUpperCase()
            : char
        )
        .join('')
    )
    .join(' ');
}

export const formatToIndianRupees = (amount) => {
  if (amount == 0 || amount == undefined || amount == null) {
    return 0;
  }
  let [wholePart, decimalPart] = amount?.toString().split('.');
  let lastThreeDigits = wholePart?.slice(-3);
  let otherDigits = wholePart?.slice(0, -3);

  if (otherDigits !== '') {
    lastThreeDigits = ',' + lastThreeDigits;
  }

  let formatted = otherDigits.replace(/\B(?=(\d{2})+(?!\d))/g, ',') + lastThreeDigits;

  return decimalPart ? formatted + '.' + decimalPart : formatted;
};


