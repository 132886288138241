import React, { useEffect, useState } from 'react'
import '../../features/Coupon/AddCouponFrom.css';
import InputField from '../../ReusableComponents/InputField';
import Form from '../../ReusableComponents/Form';
import Loading from '../../ReusableFunctions/Loader';
import Popup from '../../ReusableFunctions/Popup';
import { createAdminCoupon, getAdminCoupon, updateAdminCoupon } from '../../apis/coupons';
import ToggleField from '../../ReusableComponents/ToggleField';
import { getAllDSPs } from '../../apis/adminAPIs/dsp';
import Modal from '../../ReusableComponents/Modal';
import { Button } from '../../Components/Helper';
import SelectField from '../../ReusableComponents/SelectField';
import DatePickerField from '../../ReusableComponents/DatePickerField';
import { NoOfUserValidation, couponMessageValidation, discountCostValidation, discountValidation, nameValidation, redemptionCountValidation, selectFieldValidation } from '../../utils/inputValidations';
import CustomToggle from '../../ReusableComponents/CustomToggle';
import CustomSelect from '../../ReusableComponents/CustomSelect';
import TextAreaField from '../../ReusableComponents/textAreaField';

export default function AddAdminCouponForm({ couponId, onRequestClose }) {
    const [dsps, setDsps] = useState([])
    const [coupon, setCoupon] = useState({
        name: "",
        discount_percentage: "",
        discount_cost: '',
        max_discount_value: "",
        min_order_value: "",
        max_consumption_amount: 100000,
        max_redemption_count: null,
        number_of_use_per_user: "",
        is_dsp_specific: false,
        expiry_date: new Date(),
        from_date: new Date(),
        description: "",
        coupon_code: "",
        status: true,
        is_discount_in_percentage: true,
        dsp_id: undefined,
        consumed_amount: 0,
        locations: [],
        is_public: true,
        dsps: [],
        is_dsps_edited: false
    })

    const fetchAdminIntialData = async () => {
        try {
            const res = await getAllDSPs();
            setDsps(res.dsps);
            try {
                if (couponId) {
                    const data = await getAdminCoupon(couponId);
                    setCoupon(prev => ({
                        ...prev,
                        ...data.coupon,
                        dsps: data?.coupon?.dsps.map((l) => {
                            const dsp = res.dsps.find((ds) => ds.dsp_id === l);
                            return { label: dsp?.dsp_name, value: l };
                        }),
                        expiry_date: new Date(data.coupon.expiry_date),
                        from_date: new Date(data.coupon.from_date),
                    }));
                }
                Loading.set(false)
            } catch (err) {
                Loading.set(false)
                Popup.alert("Coupon", "Something Went wrong while getting coupon !", 'ERROR')
            }
        } catch (err) {
            Loading.set(false)
            Popup.alert("Coupon", "Something Went wrong while fetch dsps !", 'ERROR')
        }
    }

    const handleAddCoupon = (coupon) => {
        Loading.set(true);
        let modifiedCoupon = {
            ...coupon,
            expiry_date: new Date(coupon.expiry_date).setHours(23, 59, 59, 999),
            from_date: new Date(coupon.from_date).setHours(0, 0, 0, 0),
            dsps: coupon?.dsps.map((l) => l?.value)
        }
        modifiedCoupon = { ...modifiedCoupon, expiry_date: new Date(modifiedCoupon.expiry_date).toISOString(), from_date: new Date(modifiedCoupon.from_date).toISOString() }
        createAdminCoupon(modifiedCoupon).then((res) => {
            Popup.alert("Coupon", "Coupon created successfully !", 'SUCCESS')
            Loading.set(false);
            onRequestClose()
        }).catch((err) => {
            console.log("error", err);
            Loading.set(false);
            Popup.alert("Coupon", "Something Went wrong while creating coupon !", 'ERROR')
        })
    }

    const handleUpdateCoupon = (coupon) => {
        Loading.set(true);
        let modifiedCoupon = {
            ...coupon,
            expiry_date: new Date(coupon.expiry_date).setHours(23, 59, 59, 999),
            dsps: coupon?.dsps.map((l) => l?.value),
            is_dsps_edited: true
        }
        modifiedCoupon = { ...modifiedCoupon, expiry_date: new Date(modifiedCoupon.expiry_date).toISOString(), from_date: new Date(modifiedCoupon.from_date).toISOString() }
        updateAdminCoupon(modifiedCoupon).then((res) => {
            Popup.alert("Coupon", "Coupon updated successfully !", 'SUCCESS')
            Loading.set(false);
            onRequestClose()
        }).catch((err) => {
            console.log("error", err);
            Loading.set(false);
            Popup.alert("Coupon", "Something Went wrong while updating coupon !", 'ERROR')
        })
    }

    useEffect(() => {
        Loading.set(true);
        fetchAdminIntialData();
    }, [])
    console.log('coupon:', coupon)
    return (
        <Modal
            header={couponId ? "Edit Coupon" : "Create Coupon"}
            open={true}
            onClose={onRequestClose}
            contentStyle={{ width: "725px", height: "780px", borderRadius: "10px" }}
            headerContainerStyle={{ padding: "25px 30px" }}
        >
            <Form
                onSubmitForm={(coupon) => {
                    couponId ? handleUpdateCoupon(coupon) : handleAddCoupon(coupon)
                }}
                initialValues={coupon}
                style={{ display: 'flex', flexDirection: 'column', width: "100%", height: 'calc(100% - 75px)', padding: "20px 30px" }}
            >
                {(onSubmit, formValue) => (
                    <>
                        <div
                            id='coupen-form'
                            style={{
                                width: "100%",
                                height: '100%',
                                display: 'grid',
                                gridTemplateColumns: '1fr 1fr',
                                // gridTemplateRows: "repeat(auto-fill, minmax(80px, 1fr))",
                                gridAutoRows: 'auto',
                                gap: '5px 25px',
                                overflow: 'auto'
                            }} >
                            <div className='input_field'>
                                <CustomSelect
                                    label={"Visibility"}
                                    name={"is_public"}
                                />
                            </div>
                            <div className='input_field'>
                                <CustomToggle
                                    onText={"Percentage"}
                                    offText={'Rupees'}
                                    label={"Discount Type"}
                                    name={"is_discount_in_percentage"}
                                />
                            </div>
                            <InputField
                                label={"Coupon Code"}
                                placeholder={"Enter coupon code "}
                                name={'coupon_code'}
                                validation={{
                                    required: 'Required',
                                    maxLength: {
                                        value: 15,
                                        message: 'Max 15 characters.',
                                    },
                                    minLength: {
                                        value: 3,
                                        message: "At least 3 characters.",
                                    },
                                }}
                                type={'text'}
                            />

                            <InputField
                                label={"Minimum Order Value"}
                                placeholder={"Enter minimum order amount "}
                                name={'min_order_value'}
                                type={'text'}
                                validation={discountCostValidation}
                            />
                            <TextAreaField
                                label={"Message To Be Displayed On Coupon"}
                                name={"description"}
                                placeholder={"Message to display on coupon"}
                                type={"textarea"}
                                style={{ resize: "none", height: "145px", padding: "6px 8px", background: "#F3F3F3", outline: "none" }}
                                validation={couponMessageValidation}
                            />
                            <div style={{ display: "flex", flexDirection: "column", gap: "32px" }}>
                                {!formValue.is_discount_in_percentage &&
                                    <InputField
                                        label={"Fixed Discount Cost"}
                                        placeholder={"Enter discount price "}
                                        name={'discount_cost'}
                                        type={'text'}
                                        validation={discountCostValidation}
                                    />
                                }
                                {formValue.is_discount_in_percentage &&
                                    <InputField
                                        label={"Discount Percentage %"}
                                        type={'text'}
                                        placeholder={"Enter discount percentage "}
                                        name={'discount_percentage'}
                                        validation={discountValidation}
                                    />
                                }
                                {formValue.is_discount_in_percentage &&
                                    <InputField
                                        label={"Maximum Discount Amount"}
                                        placeholder={"Enter maximum discount amount "}
                                        name={'max_discount_value'}
                                        type={'text'}
                                        validation={discountCostValidation}
                                    />
                                }
                            </div>

                            <div style={{}}>
                                <div style={{ fontSize: '13px', color: '#3B3B3B' }}>Validity</div>
                                <div style={{ display: "flex", width: "100%", justifyContent: "space-between" }}>
                                    <div style={{ width: "45%" }}>
                                        <DatePickerField
                                            label={"From Date"}
                                            name={"from_date"}
                                            dependentDateFields={["from_date", "expiry_date"]}
                                            validation={{
                                                validate: (value) => {
                                                    const expiryDate = new Date(formValue.expiry_date);
                                                    return value <= expiryDate || "From date cannot be greater than To date";
                                                },
                                            }}
                                        />
                                    </div>
                                    <div style={{ width: "45%" }}>
                                        <DatePickerField
                                            dependentDateFields={["from_date", "expiry_date"]}
                                            label={"To Date"}
                                            name={"expiry_date"}
                                            validation={{
                                                validate: (value) => {
                                                    const fromDate = new Date(formValue.from_date);
                                                    return value >= fromDate || "To date cannot be less than From date";
                                                },
                                            }}
                                        />
                                    </div>
                                </div>
                            </div>

                            <InputField
                                label={"Maximum Usage Per User"}
                                placeholder={"Enter number of use per user "}
                                name={'number_of_use_per_user'}
                                type={'text'}
                                containerStyle={{ marginTop: "20px" }}
                                validation={NoOfUserValidation}
                            />

                            {/* {!formValue.is_discount_in_percentage && <div style={{ width: "100%" }}></div>} */}
                            <div style={{ display: "flex", gap: "2px", flexDirection: "column" }}>
                                <ToggleField
                                    name={'is_dsp_specific'}
                                    offText={"Select DSP"}
                                    onText={" "}
                                    labelStyle={{ fontSize: '13px', color: '#3B3B3B', }}
                                />
                                {formValue.is_dsp_specific &&
                                    <div style={{ marginTop: "-9px", position: 'relative' }}>
                                        <SelectField
                                            name='dsps'
                                            isMulti
                                            styles={{ width: "215px", height: "18px", fontSize: "13px", }}
                                            list={dsps.map(l => ({ label: l.dsp_name, value: l.dsp_id }))}
                                            containerStyle={{
                                                width: "100%",
                                                maxWidth: "100%",
                                                minHeight: "80px",
                                            }}
                                            menuPortalTarget={document.getElementById('modalHolder')}
                                            validation={selectFieldValidation}
                                            controlStyle={{ height: "auto" }}
                                        />
                                    </div>
                                }

                            </div>

                            <InputField
                                label={"Maximum Redemption Count"}
                                placeholder={"Enter maximum redemption count"}
                                name={'max_redemption_count'}
                                type={'text'}
                                containerStyle={{ marginTop: "14px" }}
                                validation={redemptionCountValidation}
                            />

                        </div>
                        <div
                            style={{
                                display: "flex",
                                flexDirection: "row-reverse",
                                padding: "10px 0px 15px",
                                gap: "20px"
                            }}
                        >
                            <Button
                                text={couponId ? "Update" : "Create"}
                                className={"btn-large"}
                                type={"submit"}
                                onClick={onSubmit}
                            />
                            <Button
                                text={"Cancel"}
                                type={"button"}
                                onClick={onRequestClose}
                                isBtnNotActive={true}
                                className={"btn-large "}
                            />
                        </div>
                    </>
                )}
            </Form>
        </Modal>
    )
}

